import { Capacitor } from '@capacitor/core';
import { IonContent } from '@ionic/react';
import React from 'react';
import { Data } from '../services/data-manager';

import {notifyDanger} from '../utils/index';

export default class Solde extends React.Component {
    nIntervale = 0;
    constructor(props) {
        super(props);
        this.state = {
            utilisateur: {}
        }
        this.nIntervale = 0;
        this.refresh = this.refresh.bind(this);
        this.suppressionCompte = this.suppressionCompte.bind(this);
    }
    suppressionCompte(event) {
        event.preventDefault();
        let confirmation = window.confirm("En supprimant votre compte, vous ne pourrez plus utiliser les services de AfricPay. Vous allez perdre votre solde et tous les historiques.");
        if (confirmation===true) {
            this.props.isLoading(true);
            new Data()
            .delete("Utilisateur", "id="+this.props.auth.utilisateur.id)
            .then(res => {
                window.alert("Votre compte à été définitivement supprimé !")
                this.props.auth.deconnexion();
            })
            .catch(error => {
                window.alert("Une erreur s'est produit lors de la suppression. Vérifier votre connexion internet puis réessayer !");
            })
            .finally(() => {
                this.props.isLoading(false);
            });
        }
    }
    componentDidMount() {
        this.refresh();
        this.nIntervale = window.setInterval(this.refresh,10000);
    }
    componentWillUnmount() {
        window.clearInterval(this.nIntervale);
    }
    refresh() {
        this.props.auth.refreshCredential();
        this.setState({utilisateur: this.props.auth.utilisateur});
    }
    render() {
        return (
            <IonContent className="alert-light">
                <div className="container-fluid mt-5 pt-3 d-flex flex-column justify-content-between">
                    <div className="row">
                        <div className={"col-sm-12 mb-3 text-center " + (Capacitor.platform==="ios" ? "mt-5":"mt-3")}>
                            <h3>Hi {this.props.auth.utilisateur.prenom} !</h3>
                            <div>Bienvenue dans votre tableau de bord.</div>
                        </div>
                        <div className="col-sm-12 mb-3">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card text-center text-dark" >
                                        <div className="card card-body">
                                            <h4>SOLDE</h4>
                                            <h3>{new Intl.NumberFormat().format(this.state.utilisateur.solde)}</h3>
                                            <div>{this.props.auth.utilisateur.devise}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 mb-3">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card text-center text-dark" >
                                        <div className="card card-body">
                                            <h4>MON CODE</h4>
                                            <h3>#{this.props.auth.utilisateur.id}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-3 text-center">
                        <span onClick={this.suppressionCompte} className="link text-danger">Supprimer mon compte</span>
                    </div>
                </div>
            </IonContent>
        );
    }
}