/**
 * global variables
 */
const ENV = "default"; //"marchand"; //"admin"; //

const apiBaseURL = "https://api.africpay.ca/api"; // "https://api.cash-mobile.legrandsoft.com/api"; //

function montantMax(codePays="CM", isAirtime=false) {
    switch (codePays) {
        case "CI":
        case "ML":
        case "BF":
        case "SN":
            return (isAirtime)?150000:2000000;
        case "GN":
            return (isAirtime)?500000:5000000;
        default: // CM
            return (isAirtime)?150000:500000;
    }
}

function montantMin(codePays="CM", isAirtime=false) {
    switch (codePays) {
        case "CI":
        case "ML":
        case "BF":
        case "SN":
            return (isAirtime)?100:500;
        case "GN":
            return (isAirtime)?500:500;
        default:
            return (isAirtime)?100:500;
    }
}

const modesPaiementMobile = [
    /* CAMEROUN */
    { name: "Orange Cameroun", operateur: "ORANGE", devise: "XAF", pays: "CM", paysLabel: "Cameroun", hasAirtime: true, hasMomo: true, hasBill: false },
    { name: "MTN Cameroun", operateur: "MTN", devise: "XAF", pays: "CM", paysLabel: "Cameroun", hasAirtime: true, hasMomo: true, hasBill: false },
    { name: "Express Union Mobile", operateur: "EU", devise: "XAF", pays: "CM", paysLabel: "Cameroun", hasAirtime: false, hasMomo: true, hasBill: false },
    { name: "YUP Cameroun", operateur: "YUP", devise: "XAF", pays: "CM", paysLabel: "Cameroun", hasAirtime: false, hasMomo: true, hasBill: false },
    { name: "NEXTTEL Cameroun", operateur: "NEXTELL", devise: "XAF", pays: "CM", paysLabel: "Cameroun", hasAirtime: true, hasMomo: false, hasBill: false },
    { name: "YOOMEE Cameroun", operateur: "YOOMEE", devise: "XAF", pays: "CM", paysLabel: "Cameroun", hasAirtime: true, hasMomo: false, hasBill: false },
    { name: "ENEO", operateur: "ENEO", devise: "XAF", pays: "CM", paysLabel: "Cameroun", hasAirtime: false, hasMomo: false, hasBill: true },
    /* COTE D'IVOIRE */
    { name: "Orange Côte d'Ivoire", operateur: "OM-CI", devise: "XOF", pays: "CI", paysLabel: "Côte d'Ivoire", hasAirtime: true, hasMomo: true, hasBill: false },
    { name: "MTN Côte d'Ivoire", operateur: "MTN-CI", devise: "XOF", pays: "CI", paysLabel: "Côte d'Ivoire", hasAirtime: true, hasMomo: true, hasBill: false },
    { name: "MOOV", operateur: "MOOV", devise: "XOF", pays: "CI", paysLabel: "Côte d'Ivoire", hasAirtime: false, hasMomo: true, hasBill: false },
    { name: "YUP Côte d'Ivoire", operateur: "YUP-CI", devise: "XOF", pays: "CI", paysLabel: "Côte d'Ivoire", hasAirtime: false, hasMomo: true, hasBill: false },
    { name: "ADVANS Côte d'Ivoire", operateur: "ADVANS", devise: "XOF", pays: "CI", paysLabel: "Côte d'Ivoire", hasAirtime: false, hasMomo: true, hasBill: false },
    { name: "WIZALL", operateur: "WIZALL-WALLET", devise: "XOF", pays: "CI", paysLabel: "Côte d'Ivoire", hasAirtime: false, hasMomo: true, hasBill: false },
    { name: "CIE", operateur: "CIE", devise: "XOF", pays: "CI", paysLabel: "Côte d'Ivoire", hasAirtime: false, hasMomo: false, hasBill: true },
    { name: "SODECI", operateur: "SODECI", devise: "XOF", pays: "CI", paysLabel: "Côte d'Ivoire", hasAirtime: false, hasMomo: false, hasBill: true },
    /* MALI */
    { name: "Orange Mali", operateur: "ORANGE-ML", devise: "XOF", pays: "ML", paysLabel: "Mali", hasAirtime: true, hasMomo: true, hasBill: false },
    { name: "MOBICASH Mali", operateur: "MOBICASH-ML", devise: "XOF", pays: "ML", paysLabel: "Mali", hasAirtime: false, hasMomo: true, hasBill: false },
    { name: "TELECEL Mali", operateur: "TELECEL-ML", devise: "XOF", pays: "ML", paysLabel: "Mali", hasAirtime: true, hasMomo: false, hasBill: false },
    { name: "MALITEL Mali", operateur: "MALITEL-ML", devise: "XOF", pays: "ML", paysLabel: "Mali", hasAirtime: true, hasMomo: false, hasBill: false },
    { name: "ENERGIA", operateur: "ENERGIA-ML", devise: "XOF", pays: "ML", paysLabel: "Mali", hasAirtime: false, hasMomo: false, hasBill: true },
    /* GUINEE */
    { name: "Orange Guinée", operateur: "ORANGE-GUINEE", devise: "GNF", pays: "GN", paysLabel: "Guinée", hasAirtime: true, hasMomo: true, hasBill: false },
    { name: "MTN Guinée", operateur: "MTN-GUINEE", devise: "GNF", pays: "GN", paysLabel: "Guinée", hasAirtime: true, hasMomo: true, hasBill: false },
    { name: "CELLCOM Guinée", operateur: "CELLCOM-GUINEE", devise: "GNF", pays: "GN", paysLabel: "Guinée", hasAirtime: true, hasMomo: false, hasBill: false },
    /* BURKINA FASO */
    { name: "Orange Burkina Faso", operateur: "ORANGE-BF", devise: "XOF", pays: "BF", paysLabel: "Burkina Faso", hasAirtime: true, hasMomo: true, hasBill: false },
    { name: "MOBICASH Burkina Faso", operateur: "MOBICASH-BF", devise: "XOF", pays: "BF", paysLabel: "Burkina Faso", hasAirtime: false, hasMomo: true, hasBill: false },
    { name: "YUP Burkina Faso", operateur: "YUP-BF", devise: "XOF", pays: "BF", paysLabel: "Burkina Faso", hasAirtime: false, hasMomo: true, hasBill: false },
    { name: "TELMOB Burkina Faso", operateur: "TELMOB-BF", devise: "XOF", pays: "BF", paysLabel: "Burkina Faso", hasAirtime: true, hasMomo: false, hasBill: false },
    { name: "TELECEL Burkina Faso", operateur: "TELECEL-BF", devise: "XOF", pays: "BF", paysLabel: "Burkina Faso", hasAirtime: true, hasMomo: false, hasBill: false },
    /* SENEGAL */
    { name: "Orange Sénégal", operateur: "ORANGE-SN", devise: "XOF", pays: "SN", paysLabel: "Sénégal", hasAirtime: false, hasMomo: true, hasBill: false },
    { name: "EMONEY Sénégal", operateur: "EMONEY-SN", devise: "XOF", pays: "SN", paysLabel: "Sénégal", hasAirtime: false, hasMomo: true, hasBill: false },
    { name: "FREE Sénégal", operateur: "FREE-SN", devise: "XOF", pays: "SN", paysLabel: "Sénégal", hasAirtime: false, hasMomo: true, hasBill: false },
    { name: "YUP Sénégal", operateur: "YUP-SN", devise: "XOF", pays: "SN", paysLabel: "Sénégal", hasAirtime: false, hasMomo: true, hasBill: false },
    /*
    { name: "MALITEL MobiCash", operateur: "MALITEL", devise: "XOF", pays: "MA" },
    { name: "AIRTEL", operateur: "AIRTEL", devise: "KES", pays: "KN" },
    { name: "SAFARICOM", operateur: "SAFARICOM", devise: "KES", pays: "KN" }*/
];

const TAUX_TRANSACTION = 3;

const pays = [
    {name: "Allemagne", devise: "EUR", iso: "AL", code: 49},
    {name: "Belgique", devise: "EUR", iso: "BE", code: 32},
    {name: "Burkina Faso", devise: "XOF", iso: "BF", code: 226},
    {name: "Cameroun", devise: "XAF", iso: "CM", code: 237},
    {name: "Canada", devise: "CAD", iso: "CA", code: 1},
    {name: "Chine", devise: "RMB", iso: "CN", code: 86},
    {name: "Côte d'Ivoire", devise: "XOF", iso: "CI", code: 225},
    {name: "Espagne", devise: "EUR", iso: "ES", code: 34},
    {name: "Etats Unis d'Amérique", devise: "USD", iso: "US", code: 1},
    {name: "France", devise: "EUR", iso: "FR", code: 33},
    {name: "Guinnée", devise: "GNF", iso: "GN", code: 224},
    {name: "Italie", devise: "EUR", iso: "IT", code: 39},
    {name: "Mali", devise: "XOF", iso: "ML", code: 223},
    {name: "Royaume Uni", devise: "GBP", iso: "GB", code: 44},
    {name: "Sénégal", devise: "XOF", iso: "SN", code: 221}
];

const RETRAIT_REFRESH_TIMER = 2 * 60 * 1000;

export {
    RETRAIT_REFRESH_TIMER,
    TAUX_TRANSACTION,
    modesPaiementMobile, 
    apiBaseURL, 
    ENV, 
    pays,
    montantMax,
    montantMin
};