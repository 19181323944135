import React from 'react';

import { notifyDanger } from '../utils/index';
import { Data } from '../services/data-manager';
import { pays } from '../..';
import { IonContent } from '@ionic/react';
import { Link } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';

export default class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            utilisateur: {
                nom: "",
                prenom: "",
                nomUtilisateur: null,
                sexe: "Homme",
                dateNaissance: null,
                piece: null,
                numeroPiece: null,
                pays: "",
                devise: "XAF",
                region: "",
                ville: null,
                adresse: null,
                email: null,
                telephone: null,
                commentaire: "",
                type: 0,
                pass: "",
                estNouveau: 0,
                estBloque: 0,
                estConnecte: true,
                langue: "fr",
                solde: 0,
                notifyAllTransaction: true,
                newsLetter: true,
                idUtilisateur: 0,
                conditionUtilisation: true
            }
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleSignInForm = this.handleSignInForm.bind(this);
        this.checkUser = this.checkUser.bind(this);
    }
    componentDidMount() {
    }
    handleInput(event) {
        const target = event.target;
        let name = event.target.name;
        let value;
        if (target.type==="checkbox") {
            value = target.checked;
        } else {
            value = target.value;
        }
        let utilisateur = this.state.utilisateur;
        utilisateur[name] = value;
        if (name=="pays") {
            let paysChoisi = pays.filter(p => (p.name==value));
            if (paysChoisi.length>0) utilisateur.devise = paysChoisi[0].devise;
        }
        this.setState({utilisateur: utilisateur});
    }
    handleSignInForm(event) {
        event.preventDefault();
        if (!this.state.utilisateur.conditionUtilisation) {
            alert("Vous devez accepter les termes et conditions d'utilisation pour créer votre compte.");
            return false;
        }
        this.props.isLoading(true);
        if (this.checkUser()) {
            alert("Un utilisateur existe déjà avec ce numéro de téléphone. S'il s'agit de vous, veuillez choisir l'option login.");
            this.props.isLoading(false);
        }
        new Data()
        .exec("/utilisateur", this.state.utilisateur)
        .then(res => {
            if (res.code==200) {
                this.props.auth.update(res.utilisateur, res.nouveauCredential, true);
                window.location.href = "/solde";
            }
        })
        .catch((error) => {
            console.warn("Erreur : ", error);
        })
        .finally(() => {
            this.props.isLoading(false);
        });
        return true;
    }
    async checkUser() {
        let user = await new Data().getOne("Utilisateur", `pays="${this.state.utilisateur.pays}" AND telephone="${this.state.utilisateur.telephone}"`);
        return user.pass ? user : false;
    }
    render() {
        return (
            <IonContent className="alert-light">
                <div className="container-fluid mt-5 text-dark">
                    <div className="row">
                        <div className={"col-sm-12 mb-3 text-center " + (Capacitor.platform==="ios" ? "mt-5":"mt-3")}>
                            <h3>Création de compte</h3>
                            <h5>Créez votre compte et commencez à envoyer et recevoir de l'argent.</h5>
                        </div>
                        <div className="col-sm-12 mb-3">
                            <div className="row">
                                <div className="col-sm-10 col-md-8 mx-auto">
                                    <form className="card" onSubmit={this.handleSignInForm}>
                                        <div className="card card-body">
                                            <div className="form-group">
                                                <select className="form-control" name="sexe" value={this.state.utilisateur.sexe} onChange={this.handleInput} required>
                                                    <option value="Homme">Monsieur</option>
                                                    <option value="Femme">Madame</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Nom</label>
                                                <input className="form-control" type="text" name="nom" placeholder="Nom" value={this.state.utilisateur.nom} onChange={this.handleInput} required />
                                            </div>
                                            <div className="form-group">
                                                <label>Prénom</label>
                                                <input className="form-control" type="text" name="prenom" placeholder="Prénom" value={this.state.utilisateur.prenom} onChange={this.handleInput} required />
                                            </div>
                                            <div className="form-group">
                                                <label>Pays</label>
                                                <select className="form-control" name="pays" value={this.state.utilisateur.pays} onChange={this.handleInput} required>
                                                    <option value=""></option>
                                                    {( pays.map(p => <option value={ p.name } key={'pays-'+p.name.replaceAll(/'| /g, "")}>{ p.name }</option> ) )}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Dévise utilisée</label>
                                                <select className="form-control" name="devise" value={this.state.utilisateur.devise} onChange={this.handleInput} disabled>
                                                    <option value=""></option>
                                                    {( pays.map(p => <option value={ p.devise } key={'devise-'+p.devise}>{ p.devise }</option> ) )}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Adresse</label>
                                                <input className="form-control" type="text" name="adresse" placeholder="Adresse" value={this.state.utilisateur.adresse} onChange={this.handleInput} required />
                                            </div>
                                            <div className="form-group">
                                                <label>Téléphone</label>
                                                <input className="form-control" type="number" name="telephone" placeholder="Téléphone" value={this.state.utilisateur.telephone} onChange={this.handleInput} required />
                                            </div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input className="form-control" type="text" name="email" placeholder="Email" value={this.state.utilisateur.email} onChange={this.handleInput} />
                                            </div>
                                            <div className="form-group">
                                                <label>Mot de passe</label>
                                                <input className="form-control" type="password" name="pass" placeholder="Mot de passe" value={this.state.utilisateur.pass} onChange={this.handleInput} required />
                                            </div>
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="condition-utilisation" name="conditionUtilisation" checked={this.state.utilisateur.conditionUtilisation} onChange={this.handleInput} />
                                                    <label className="custom-control-label" htmlFor="condition-utilisation">Accepter les termes et conditions d'utilisations.</label>
                                                    <p>
                                                        <a href="https://africpay.ca/cgu.html" target="new">Lire les termes et conditions générales d'utilisation.</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer text-right">
                                            <button type="submit" className="btn btn-primary">Créer mon Compte</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 mb-3 text-center">
                            <Link to="/">J'ai un compte. Me connecter.</Link>
                        </div>
                    </div>
                </div>
            </IonContent>
        );
    }
}