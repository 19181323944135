import { IonContent } from '@ionic/react';
import React from 'react';

import imgLogo from '../images/logo1024x1024.png';

export default class Apropos extends React.Component<any,any> {
    render() {
        return (
        <IonContent className="alert-success">
            <div className="container-fluid pt-5 py-3">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img src={imgLogo} alt="Logo AfricPay"/>
                        <h3>AfricPay</h3>
                    </div>
                    <div className="col-md-12">
                        <div className="border-top border-secondary py-3">
                            Version : <strong>1.0.7 (107)</strong>
                        </div>
                        <div className="border-top border-secondary py-3">
                            Description : 
                            <p>
                                AfricPay est une application qui vous permet de faire tout type de transactions mobiles vers l'Afrique avec un seul compte.
                            </p>
                        </div>
                        <div className="border-top border-secondary py-3">
                            <div>Entreprise : <strong>AFRICPAY SARL</strong></div>
                            <div>Contact : <a href="tel:+237695073661"><strong>+237 695 073 661</strong></a></div>
                        </div>
                        <div className="border-top border-bottom border-secondary py-3">
                            Lien : 
                            <a href="https://legrandsoft.com/projets/egt-cash-mobile/">
                                <strong> Projet AfricPay</strong>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </IonContent>
        );
    }
}