import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import React from 'react';
import { Plugins } from "@capacitor/core";
import { 
  listOutline, 
  listSharp, 
  paperPlaneOutline, 
  paperPlaneSharp, 
  informationCircleOutline, 
  informationCircleSharp, 
  shieldCheckmarkOutline, 
  shieldCheckmarkSharp, 
  statsChartOutline, 
  statsChartSharp, 
  logOutOutline, 
  logOutSharp, 
  exitOutline, 
  exitSharp, 
  appsSharp, 
  appsOutline, 
  copySharp, 
  copyOutline, 
  phonePortraitSharp, 
  phonePortraitOutline, 
  downloadOutline, 
  downloadSharp, 
  cloudUploadOutline, 
  cloudUploadSharp, 
  sendOutline,
  sendSharp
} from 'ionicons/icons';
import './Menu.css';
import Auth from '../services/Auth';

interface IProps {
  auth: Auth
};

export default class Menu extends React.Component<IProps, {}> {

  deconnexion() {
    this.props.auth.deconnexion();
  }

  closeApp() {
    if (window.confirm("Voulez-vous vraiment quitter l'application ?")===true) Plugins.App.exitApp();
  }

  render() {
    const location = window.location;
    return (
      <IonMenu contentId="main" type="overlay">
        <IonContent>
          {(this.props.auth.isAuth) && 
          <IonList id="inbox-list">
            <IonListHeader className="border-secondary border-bottom">AFRIC PAY</IonListHeader>
            <IonNote>Menu</IonNote>
            <IonMenuToggle autoHide={false}>
              <IonItem className={location.pathname === "/" ? 'selected' : ''} routerLink="/" routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" ios={appsOutline} md={appsSharp} />
                <IonLabel>Accueil</IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <IonItem className={location.pathname === "/transaction/depot" ? 'selected' : ''} routerLink="/transaction/depot" routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" ios={downloadOutline} md={downloadSharp} />
                <IonLabel>Envoyer de l'argent</IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <IonItem className={location.pathname === "/transaction/retrait" ? 'selected' : ''} routerLink="/transaction/retrait" routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" ios={cloudUploadOutline} md={cloudUploadSharp} />
                <IonLabel>Recevoir de l'argent</IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <IonItem className={location.pathname === "/transaction/recharge" ? 'selected' : ''} routerLink="/transaction/recharge" routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" ios={phonePortraitOutline} md={phonePortraitSharp} />
                <IonLabel>Recharge de crédit</IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <IonItem className={location.pathname === "/transaction/facture" ? 'selected' : ''} routerLink="/transaction/facture" routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" ios={copyOutline} md={copySharp} />
                <IonLabel>Payer une facture</IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <IonItem className={location.pathname === "/transactions" ? 'selected' : ''} routerLink="/transactions" routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" ios={listOutline} md={listSharp} />
                <IonLabel>Mes transactions</IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <IonItem className={location.pathname === "/transaction/approvisionnement" ? 'selected' : ''} routerLink="/transaction/approvisionnement" routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" ios={paperPlaneOutline} md={paperPlaneSharp} />
                <IonLabel>Faire un approvisionnement</IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <IonItem className={location.pathname === "/approvisionnements" ? 'selected' : ''} routerLink="/approvisionnements" routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" ios={shieldCheckmarkOutline} md={shieldCheckmarkSharp} />
                <IonLabel>Mes approvisionnements</IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <IonItem className={location.pathname === "/approvisionnements/transfert" ? 'selected' : ''} routerLink="/approvisionnements/transfert" routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" ios={sendOutline} md={sendSharp} />
                <IonLabel>Transférer mes fonds</IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <IonItem className={location.pathname === "/solde" ? 'selected' : ''} routerLink="/solde" routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" ios={statsChartOutline} md={statsChartSharp} />
                <IonLabel>Consulter mon solde</IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>
          }

          <IonList id="labels-list">
            <IonMenuToggle>
              <IonItem className={location.pathname === "/apropos" ? 'selected' : ''} routerLink="/apropos" routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" ios={informationCircleOutline} md={informationCircleSharp} />
                <IonLabel>Informations sur l'application</IonLabel>
              </IonItem>
            </IonMenuToggle>
            {this.props.auth.isAuth &&
            <IonItem lines="none" onClick={() => this.deconnexion()}>
              <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
              <IonLabel>Me Déconnecter</IonLabel>
            </IonItem>}
            <IonItem style={{color:"red"}} lines="none" onClick={() => this.closeApp()}>
              <IonIcon slot="start" ios={exitOutline} md={exitSharp} />
              <IonLabel>Fermer l'application</IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonMenu>
    );
  }
};