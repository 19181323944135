import React from 'react';
import Modal from 'react-bootstrap/Modal';

import './bootstrap.css';

import { pays } from '../..';
import { Data } from '../services/data-manager';
import { notifyDanger, notifySuccess } from '../utils';
import { IonContent } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { Link } from 'react-router-dom';
import CryptoJS from "crypto-js";

/*
interface IProps {
    auth: Auth,
    isLoading: Function
};
interface IState {
    approvisionnement: {
        credential: string | null,
		idUtilisateur: number | null,
		idClient: number | null,
		montant: number,
		devise: string,
		commentaire: string,
		idDestinataire: number | null,
        id: number | null
    },
    inProcess: boolean,
    paymentUri: string,
    showConfirmation: boolean
};*/

export default class RechargeCarte extends React.Component {

    nTimeoutStatus = null;

    constructor(props) {
        super(props);
        this.state = {
            approvisionnement: {
                credential: null,
                idUtilisateur: null,
                idClient: null,
                montant: 0,
                devise: "XAF",
                commentaire: "Recharge du compte AFRICPAY",
                idDestinataire: null,
                id: null
            },
            inProcess: false,
            paymentUri: "",
            showConfirmation: false
        };
        this.nTimeoutStatus = 0;
        this.sendApprovisionnement = this.sendApprovisionnement.bind(this);
        this.handleInputApprovisionnement = this.handleInputApprovisionnement.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.confirmModalShow = this.confirmModalShow.bind(this);
        this.cancelPayment = this.cancelPayment.bind(this);
    }

    componentDidMount() {
        this.props.auth.refreshCredential();
    }

    componentWillUnmount() {
        if (this.nTimeoutStatus) clearTimeout(this.nTimeoutStatus);
    }

    resetForm() {
        let approvisionnement = this.state.approvisionnement;
        approvisionnement.montant = 0;
        this.setState({approvisionnement: approvisionnement});
    }

    callTouchpay(transaction) {
        /**
         * @todo
         * npm install uuid crypto-js --save
         */
        const order_number = transaction.idTransaction;
        const agency_code = 'CMSEG3670';
        const token = 'zC0sKhi5RHgi9wgJeuqNh1caA5kmAQ5Asl4gHrBsduYFVoKra4';
        const domain_name = 'evergreentech.cm';
        const ville = this.props.auth.utilisateur.ville;
        const email = this.props.auth.utilisateur.email;
        const client_first_name = this.props.auth.utilisateur.nom;
        const client_last_name = this.props.auth.utilisateur.prenom;
        const client_phone = this.props.auth.utilisateur.telephone;
        const montant = Math.ceil(transaction.montant);
        const url_ = 'https://touchpay.gutouch.com/touchpay/EntryPoint';
        const info_transaction = order_number + '|' + montant.toString() + '|' + agency_code + '|' + domain_name;
        const hmacHash = CryptoJS.HmacSHA256(info_transaction, token);
        const hash = CryptoJS.enc.Base64.stringify(hmacHash);
        const data = { 
            order_number: order_number, 
            agency_code: agency_code, 
            hash: hash, 
            domain_name: domain_name, 
            url_notif_success: "", 
            url_notif_failed: "", 
            website_location: "", 
            amount: montant, 
            ville: ville, 
            email: email, 
            clientFirstname: client_first_name, 
            clientLastname: client_last_name, 
            clientPhone: client_phone 
        };
        //const myFrame = window.open("", "my-iframe", "location=no");//, "menubar=no,resizable=no,status=no,scrollbars=no,width="+window.innerWidth+",height="+window.innerHeight); //document.createElement("iframe");
        //myFrame.name = "my-iframe";
        //myFrame.src = url_;
        //myFrame.frameBorder = 0;
        //document.body.appendChild(myFrame);
        const myForm = document.createElement("form");
        myForm.target = "_system"; //"my-iframe";
        myForm.action = url_;
        myForm.method = "POST";
        myForm.style.display = "none";
        for (const key in data) {
            let value = data[key];
            const myInput = document.createElement("input");
            myInput.name = key;
            myInput.type = (typeof value == "number")?"number":"text";
            myInput.value = value;
            myForm.appendChild(myInput);
        }
        document.body.appendChild(myForm);
        myForm.submit();
    }

    sendApprovisionnement(event) {
        event.preventDefault();

        if (!this.props.auth.isAuthenticated()) return false;
        let approvisionnement = this.state.approvisionnement;
        this.props.auth.refreshCredential();
        approvisionnement.idUtilisateur = this.props.auth.utilisateur.id;
        approvisionnement.credential = this.props.auth.getCredential();

        this.props.isLoading(true);
        new Data()
        .exec("/approvisionnement/personnel", approvisionnement)
        .then(res => {
            //console.log("approvisionnement effectuée : ", res);
            if (res.code==201) {
                notifySuccess(res.message);
                // Appel de la fenêtre pour le paiement
                this.callTouchpay(res);
                // Changer l'état et intéroger le status
                approvisionnement.id = res.idApprovisionnement;
                this.setState({
                    inProcess: true, 
                    paymentUri: res.paymentUrl,
                    approvisionnement: approvisionnement
                });
                this.props.auth.update(res.utilisateur, res.nouveauCredential, true);
                this.resetForm();
                this.nTimeoutStatus = window.setTimeout(this.getStatus, 5000, res.idApprovisionnement);
            } else {
                notifyDanger(res.message);
            }
        })
        .catch((error) => {
            window.alert("Une erreur c'est produite. Vérifier votre connexion et reessayer.");
            console.warn("Erreur : ", error);
        })
        .finally(() => {
            this.props.isLoading(false);
        });
    }

    getStatus(idApprovisionnement) {
        new Data()
        .exec("/approvisionnement/personnel/status?idApprovisionnement="+idApprovisionnement)
        .then(res => {
            //console.log("Etat : ", res);
            if (res.code==200) {
                if (res.status==="CREATED" || res.status==="PENDING") {
                    this.nTimeoutStatus = window.setTimeout(this.getStatus, 2500, idApprovisionnement);
                } else {
                    if (res.status=="SUCCESSFUL") {
                        notifySuccess("Approvisionnement confirmé !");
                    } else {
                        notifySuccess("Echec ! La approvisionnement à échouée ou n'a pas été confirmée.");
                    }
                    clearTimeout(this.nTimeoutStatus);
                    this.nTimeoutStatus = 0;
                    this.setState({inProcess: false});
                }
                this.props.auth.update(res.utilisateur, res.nouveauCredential, true);
                this.resetForm();
            } else {
                notifyDanger(res.message);
            }
        });
    }

    handleInputApprovisionnement(event) {
        let target = event.target;
        const name = target.name;
        const value = target.value;
        let approvisionnement = this.state.approvisionnement;
        approvisionnement[name] = value;
        this.setState({approvisionnement: approvisionnement});
    }

    cancelPayment() {
        clearTimeout(this.nTimeoutStatus);
        this.nTimeoutStatus = 0;
        this.setState({inProcess: false});
        new Data().exec(`/approvisionnement/personnel/annuler?idApprovisionnement=${this.state.approvisionnement.id}`);
    }

    confirmModalShow(show=true) {
        this.setState({showConfirmation: show});
    }
    
    render() {
        return (
            <IonContent className="alert-secondary">
                <div className="container-fluid mt-5 pt-2 pb-3">
                    <form action="/approvisionnement" method="post" className="contactForm" onSubmit={(e) => {this.sendApprovisionnement(e)}}>
                        <div className="">
                            <div className="row">
                                <div className={"col-sm-12 mb-3 " + (Capacitor.platform==="ios" ? "mt-5":"mt-3")}>
                                    <h3>Approvisionnement de mon compte</h3>
                                    <hr className="border-secondary" />
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="montant">Montant</label>
                                        <input type="number" name="montant" value={this.state.approvisionnement.montant} id="montant" className="form-control border-secondary" onChange={(e) => this.handleInputApprovisionnement(e)} required />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="devise">Devise</label>
                                        <select name="devise" id="devise" value={this.state.approvisionnement.devise} className="form-control border-secondary" onChange={(e) => this.handleInputApprovisionnement(e)} required>
                                            <option value=""></option>
                                            {( pays.sort((a,b) => {
                                                if (a.devise<b.devise) return -1;
                                                if (a.devise>b.devise) return 1;
                                                return 0;
                                            })
                                            .filter((item,pos) => (pos==0 || (item.devise!=pays[pos-1].devise)))
                                            .map(m => <option value={ m.devise } key={'mode-source-'+m.devise.toLocaleLowerCase()}>{ m.devise }</option> ) )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="alert alert-warning text-small">
                                        Le paiement se fait par partie tière et est sécurisé. 
                                        Les informations de votre carte bancaire ne sont ni enregistrées, 
                                        ni traitées par notre serveur mais directement envoyé à l'agrégateur.
                                    </div>
                                    <div className="alert alert-warning text-small">
                                        En cas de perte de votre carte bancaire, appelez directement votre banque 
                                        afin de la bloquer et sécuriser vos fonds.
                                    </div>
                                </div>
                                <div className="col-sm-12 mt-3 pb-5 mb-5">
                                    <button className="btn btn-primary btn-block btn-lg mb-5" type="submit">Demarrer</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <Modal className="mt-5" show={this.state.showConfirmation} onHide={() => {this.confirmModalShow(false)}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation de l'approvisionnement</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Votre approvisionnement à été effectué avec succès. 
                            Vous pouvez envoyer de l'argent en Afrique de manière instantané.
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={() => {this.confirmModalShow(false)}} type="button">Fermer</button>
                            <Link to="/transaction/depot" className="btn btn-primary">Envoyer de l'argent</Link>
                        </Modal.Footer>
                    </Modal>
                    {
                        this.state.inProcess && 
                        <div className="payment-box">
                            <iframe src={this.state.paymentUri} frameBorder="0"></iframe>
                            <div className="text-center">
                                <button className="btn btn-danger rounded-pill" onClick={this.cancelPayment}>Annuler l'approvisionnement</button>
                            </div>
                        </div>
                    }
                </div>
            </IonContent>
        );
    }
};