import React from 'react';
import { checkmarkCircleSharp, closeCircleSharp, listCircleOutline, listCircleSharp, listOutline, listSharp, removeCircleSharp } from 'ionicons/icons';

import TableDisplay from './TableDisplay';

import { Data } from '../services/data-manager';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';
import { IonContent, IonIcon } from '@ionic/react';
import { Capacitor } from '@capacitor/core';

class Transactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transactions: [],
            page: 1,
            nbTransaction: 1,
            sizeTransaction: 50,
            transaction: null,
            showDetail: false
        };
        this.getTransactions = this.getTransactions.bind(this);
        this.getDetailTransaction = this.getDetailTransaction.bind(this);
        this.showDetailModal = this.showDetailModal.bind(this);
        this.confirmationRetrait = this.confirmationRetrait.bind(this);
    }
    componentDidMount() {
        //this.props.isLoading(true);
        this.getTransactions();
    }
    getTransactions(page=0) {
        if (!this.props.auth.isAuthenticated()) return false;
        if (page!==0) {
            this.setState({page: page});
        } else {
            page = this.state.page;
        }

        this.props.isLoading(true);
        new Data()
        .get("Transaction", "idUtilisateur="+this.props.auth.utilisateur.id, "", "dateCreation DESC", page, this.state.sizeTransaction)
        .then(res => {
            console.log(res);
            this.setState({transactions: res.rows, nbTransaction: res.count});
        })
        .catch(err => {
            console.warn("Error : ", err);
        })
        .finally(() => {
            this.props.isLoading(false);
        });
        setTimeout(() => {window.scrollTo(0,0);}, 500);
    }
    getDetailTransaction(transaction) {
        this.setState({transaction: transaction}, () => {
            this.showDetailModal();
        });
    }
    showDetailModal(show=true) {
        this.setState({showDetail: show});
    }
    confirmationRetrait(confirmation=true) {
        this.props.isLoading(true);
        this.props.auth.refreshCredential();
        new Data()
        .exec("/utilisateur/transaction/validate-retrait", {
            credential: this.props.auth.getCredential(),
            idUtilisateur: this.props.auth.utilisateur.id,
            idTransaction: this.state.transaction.idTransaction,
            confirmation: confirmation
        })
        .then(res => {
            this.setState({transaction: res.transaction});
            this.getTransactions(this.state.page);
            this.props.auth.update(res.utilisateur, res.credential);
        })
        .catch(error => {
            console.warn("Error : ", error);
            window.alert("Une erreur c'est produite. Vérifiez votre connexion et reessayez.");
        })
        .finally(() => {
            this.props.isLoading(false);
        })
    }
    render() {
        return (
            <IonContent className="alert-light">
                <div className="container-fluid mt-5 pt-2">
                    <div className="row">
                        <div className={"col-sm-12 mb-3 " + (Capacitor.platform==="ios" ? "mt-5":"mt-3")}>
                            <h3>Transactions [Page {this.state.page}/{(this.state.nbTransaction==0)?1:Math.ceil(this.state.nbTransaction/this.state.sizeTransaction)}]</h3>
                        </div>
                        <div className="col-sm-12 mb-3">
                            <TableDisplay 
                                tableStriped={true}
                                tableHover={true}
                                tableClass="alert-light"
                                thead="dark"
                                titles={["TYPE", "MONTANT", "", "ETAT"]}
                                indices={["type", "montant", "devise", "status"]}
                                data={this.state.transactions}
                                actions={[
                                    {caption: <IonIcon slot="start" ios={listOutline} md={listSharp} />, className:"btn btn-sm btn-primary mr-1", onClick:this.getDetailTransaction}
                                ]}
                                withPaging={true}
                                pagingTheme="primary"
                                nb={this.state.nbTransaction}
                                size={this.state.sizeTransaction}
                                current={this.state.page}
                                onChangePage={this.getTransactions} />
                        </div>
                    </div>
                    {(this.state.transaction) &&
                    <Modal show={this.state.showDetail} onHide={() => {this.showDetailModal(false)}} className="mt-5">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Détails de la transaction #{this.state.transaction.id}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mx-3">
                                <table className="table table-sm">
                                    <tbody>
                                        <tr className="row">
                                            <td className="col-5">ID : </td>
                                            <td className="col-7">{this.state.transaction.id}</td>
                                        </tr>
                                        <tr className="row">
                                            <td className="col-5">Type : </td>
                                            <td className="col-7">{this.state.transaction.type}</td>
                                        </tr>
                                        <tr className="row">
                                            <td className="col-5">Bénéficiaire : </td>
                                            <td className="col-7">{this.state.transaction.beneficiaire}</td>
                                        </tr>
                                        <tr className="row">
                                            <td className="col-5">Numéro de téléphone : </td>
                                            <td className="col-7">{this.state.transaction.numero}</td>
                                        </tr>
                                        <tr className="row">
                                            <td className="col-5">Montant : </td>
                                            <td className="col-7">{Intl.NumberFormat().format(this.state.transaction.montant)} {this.state.transaction.devise}</td>
                                        </tr>
                                        <tr className="row">
                                            <td className="col-5">Opérateur : </td>
                                            <td className="col-7">{this.state.transaction.operateur}</td>
                                        </tr>
                                        <tr className="row">
                                            <td className="col-5">Pays : </td>
                                            <td className="col-7">{this.state.transaction.pays}</td>
                                        </tr>
                                        <tr className="row">
                                            <td className="col-5">Valeur réelle : </td>
                                            <td className="col-7">{Intl.NumberFormat().format(this.state.transaction.valeurReelle)} {this.props.auth.utilisateur.devise}</td>
                                        </tr>
                                        <tr className="row">
                                            <td className="col-5">Date : </td>
                                            <td className="col-7"><Moment format="DD/MM/YYYY à HH:mm">{this.state.transaction.dateCreation}</Moment></td>
                                        </tr>
                                        <tr className="row">
                                            <td className="col-5">Etat : </td>
                                            <td className="col-7">
                                                <span>{this.state.transaction.status}</span> 
                                                {(["SUCCESSFUL","PENDING","FAILED"].indexOf(this.state.transaction.status)>=0) &&
                                                <IonIcon slot="start" 
                                                    style={{fontSize: "22px", position: "relative", top: "4px"}} 
                                                    className={"ml-2 text-"+((this.state.transaction.status=="SUCCESSFUL")?"success":((this.state.transaction.status=="FAILED")?"danger":"warning"))} 
                                                    ios={(this.state.transaction.status=="SUCCESSFUL")?checkmarkCircleSharp:((this.state.transaction.status=="FAILED")?closeCircleSharp:removeCircleSharp)} 
                                                    md={(this.state.transaction.status=="SUCCESSFUL")?checkmarkCircleSharp:((this.state.transaction.status=="FAILED")?closeCircleSharp:removeCircleSharp)}>
                                                </IonIcon>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="row">
                                            <td className="col-5">Date de validation : </td>
                                            <td className="col-7"><Moment format="DD/MM/YYYY à HH:mm">{this.state.transaction.dateValidation}</Moment></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="text-right">
                            {
                                (this.state.transaction)?(
                                    (this.state.transaction.type=="RETRAIT GUICHET" && this.state.transaction.status=="PENDING") &&
                                    <>
                                        <button className="btn btn-success" onClick={() => {this.confirmationRetrait(true)}}>Accepter</button>
                                        <button className="btn btn-danger" onClick={() => {this.confirmationRetrait(false)}}>Refuser</button>
                                    </>
                                ):""
                            }
                        </Modal.Footer>
                    </Modal>
                    }
                </div>
            </IonContent>
            
        );
    }
}

export default Transactions;