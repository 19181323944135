import React from 'react';

import Auth from '../services/Auth';
import Transaction from './Transaction';

interface IProps {
    auth: Auth,
    isLoading: Function
};

export default class Retrait extends React.Component<IProps,any> {
    render() {
        return <Transaction auth={this.props.auth} isLoading={this.props.isLoading} isMomo={true} isCashIn={false} isCashOut={true} />
    }
}