import { Capacitor } from '@capacitor/core';
import { IonContent } from '@ionic/react';
import React from 'react';
import { Link } from 'react-router-dom';

import {notifyDanger} from '../utils/index';

var imgDepot = require("../images/depot-mobile.jpeg");
var imgRetrait = require("../images/transfere-mobile.jpeg");
var imgRecharge = require("../images/recharge-mobile.jpeg");
var imgFacture = require("../images/paiement-facture.jpeg");

export default class Accueil extends React.Component {
    render() {
        return (
            <IonContent className="alert-light">
                <div className="container-fluid mt-5 pt-2 pb-3">
                    <div className="row">
                        <div className={"col-sm-12 mb-3 text-center " + (Capacitor.platform==="ios" ? "mt-5":"mt-3")}>
                            <h3 className="card card-body rounded-pill alert-info">Hi {this.props.auth.utilisateur.prenom} !</h3>
                            <div>Que voulez-vous faire !</div>
                        </div>
                        <div className="col-sm-12 mb-3">
                            <div className="row">
                                <div className="col-6">
                                    <Link to="/transaction/depot" className="card text-center" >
                                        <div className="card card-body">
                                            <img src={imgDepot} alt="Faire un Dépot sur AfricPay" className="img-fluid" />
                                            <strong>ENVOYER DE L'ARGENT</strong>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-6">
                                    <Link to="/transaction/retrait" className="card text-center" >
                                        <div className="card card-body">
                                            <img src={imgRetrait} alt="Faire un Retrait sur AfricPay" className="img-fluid" />
                                            <strong>RECEVOIR DE L'ARGENT</strong>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-6 mt-3">
                                    <Link to="/transaction/recharge" className="card text-center" >
                                        <div className="card card-body">
                                            <img src={imgRecharge} alt="Faire une Recharge téléphonique sur AfricPay" className="img-fluid" />
                                            <strong>RECHARGE DE CREDIT</strong>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-6 mt-3">
                                    <Link to="/transaction/facture" className="card text-center" >
                                        <div className="card card-body">
                                            <img src={imgFacture} alt="Payer une facture sur AfricPay" className="img-fluid" />
                                            <strong>PAIEMENT FACTURE</strong>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        );
    }
}