import moment from 'moment';
import {Data} from './data-manager';

export default class Auth {
    utilisateur = null;
    credential = null;
    isAuth = false;
    constructor() {
        this.getUtilisateur();
        this.getCredential();
        this.deconnexion = this.deconnexion.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.update = this.update.bind(this);
    }
    login(login, password, remember=false) {
        return new Data()
        .exec(
            "/utilisateur/login", 
            {
                login: login,
                pass: password
            }
        )
        .then(res => {
            console.log(res);
            if (res.utilisateur) {
                this.isAuth = true;
                this.update(res.utilisateur, res.nouveauCredential, true);
                localStorage.setItem("remember", remember);
                window.location.reload();
                return true;
            } else {
                return false;
            }
        });
    }
    deconnexion() {
        localStorage.clear();
        this.isAuth = false;
        this.utilisateur = null;
        window.location.reload();
    }
    update(utilisateur=null, credential=null, refreshExpiration=false, remember=false) {
        if (utilisateur!==null) {
            this.utilisateur = utilisateur;
            localStorage.setItem("utilisateur", JSON.stringify(utilisateur));
        }
        if (credential!==null) {
            this.credential = credential;
            localStorage.setItem("credential", credential);
        }
        if (refreshExpiration) {
            // Expiration
            let date = moment();
            let dateExpiration = date.add(30,"minutes");
            localStorage.setItem("dateCreation", date.format("YYYY-MM-DD HH:mm:ss"));
            localStorage.setItem("dateExpiration", (remember)?0:dateExpiration.format("YYYY-MM-DD HH:mm:ss"));
        }
    }
    async refreshCredential() {
        let res = await new Data()
        .exec("/utilisateur/credential", {
            id: this.utilisateur.id,
            pass: this.utilisateur.pass
        });
        if (res.code==200) {
            this.update(res.utilisateur, res.nouveauCredential, false);
        }
    }
    isAuthenticated() {
        this.isExpired();
        return this.isAuth;
    }
    getUtilisateur() {
        this.isExpired();
        if (!this.utilisateur) {
            this.utilisateur = JSON.parse(localStorage.getItem("utilisateur"));
        }
        this.isAuth = this.utilisateur!==null;
        return this.utilisateur;
    }
    getCredential() {
        this.isExpired();
        if (!this.credential) {
            this.credential = localStorage.getItem("credential");
        }
        return this.credential;
    }
    isExpired() {
        let result = false;
        let remember = localStorage.getItem("remember");
        if (remember) result = true;
        else {
            let sDateExpiration = localStorage.getItem("dateExpiration");
            let dateExpiration = moment(sDateExpiration, "YYYY-MM-DD HH:mm:ss");
            let date = moment();
            if (dateExpiration.diff(date,"s")<0) {
                localStorage.clear();
                this.utilisateur = null;
                this.isAuth = false;
                result = true;
                window.location.reload();
            } else {
                result = false;
            }
        }
        return result;
    }
};