import React from 'react';

import { notifyDanger } from '../utils/index';
import { Data } from '../services/data-manager';
import { pays } from '../..';
import { IonContent } from '@ionic/react';
import { Link } from 'react-router-dom';

export default class RecupPass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            utilisateur: {
                id: null,
                nom: "",
                prenom: "",
                sexe: "Homme",
                pays: "",
                adresse: "",
                telephone: "",
                conditionUtilisation: false,
                pass: "",
                pass2: ""
            }
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleRecupPassForm = this.handleRecupPassForm.bind(this);
        this.checkUser = this.checkUser.bind(this);
    }
    componentDidMount() {
    }
    handleInput(event) {
        const target = event.target;
        let name = event.target.name;
        let value;
        if (target.type==="checkbox") {
            value = target.checked;
        } else {
            value = target.value;
        }
        let utilisateur = this.state.utilisateur;
        if (name=="conditionUtilisation" && value) {
            this.checkUser().then(userId => {
                console.log(userId);
                if (userId) {
                    utilisateur.id = userId;
                } else {
                    value = false;
                }
                utilisateur.conditionUtilisation = value;
                this.setState({utilisateur: utilisateur});
            });
        } else {
            utilisateur[name] = value;
            this.setState({utilisateur: utilisateur});
        }
    }
    handleRecupPassForm(event) {
        event.preventDefault();
        if (!this.state.utilisateur.conditionUtilisation) {
            alert("Vous devez confirmer avoir renseigné vos informations réelles avant de valider le formulaire de demande de mot de passe.");
            return false;
        }
        this.props.isLoading(true);
        new Data().exec("/utilisateur/recuppass", this.state.utilisateur)
        .then(res => {
            alert(res.message);
            if (!res.error) {
                window.location.href = "/";
            }
        })
        .catch(error => {
            alert("Une erreur est survenue. Vérifiez votre connexion et reessayer.");
            console.warn("Erreur : ", error);
        })
        .finally(() => {
            this.props.isLoading(false);
        });
        return true;
    }
    async checkUser() {
        this.props.isLoading(true);
        const user = await new Data().getOne("Utilisateur", `sexe="${this.state.utilisateur.sexe}" AND LOWER(nom)="${this.state.utilisateur.nom.toLowerCase()}" AND LOWER(prenom)="${this.state.utilisateur.prenom.toLowerCase()}" AND pays="${this.state.utilisateur.pays}" AND LOWER(adresse)="${this.state.utilisateur.adresse.toLowerCase()}" AND telephone="${this.state.utilisateur.telephone}" AND type=0`);
        this.props.isLoading(false);
        return user.pass ? user.id : false;
    }
    render() {
        return (
            <IonContent className="alert-light">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 my-3 text-center">
                            <h3>Récupération du mot de passe</h3>
                            <div>Pour récupérer votre mot de passe, veuillez bien renseigner le présent formulaire.</div>
                        </div>
                        <div className="col-sm-12 mb-3">
                            <div className="row">
                                <div className="col-sm-10 col-md-8 col-lg-6 col-xl-4 mx-auto">
                                    <form className="card" onSubmit={this.handleRecupPassForm}>
                                        <div className="card card-body">
                                            <div className="form-group">
                                                <select className="form-control" name="sexe" value={this.state.utilisateur.sexe} onChange={this.handleInput} required>
                                                    <option value="Homme">Monsieur</option>
                                                    <option value="Femme">Madame</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Nom</label>
                                                <input className="form-control" type="text" name="nom" placeholder="Nom" value={this.state.utilisateur.nom} onChange={this.handleInput} required />
                                            </div>
                                            <div className="form-group">
                                                <label>Prénom</label>
                                                <input className="form-control" type="text" name="prenom" placeholder="Prénom" value={this.state.utilisateur.prenom} onChange={this.handleInput} required />
                                            </div>
                                            <div className="form-group">
                                                <label>Pays</label>
                                                <select className="form-control" name="pays" value={this.state.utilisateur.pays} onChange={this.handleInput} required>
                                                    <option value=""></option>
                                                    {( pays.map(p => <option value={ p.name } key={'pays-'+p.name.replaceAll(/'| /g, "")}>{ p.name }</option> ) )}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Adresse</label>
                                                <input className="form-control" type="text" name="adresse" placeholder="Adresse" value={this.state.utilisateur.adresse} onChange={this.handleInput} required />
                                            </div>
                                            <div className="form-group">
                                                <label>Téléphone</label>
                                                <input className="form-control" type="number" name="telephone" placeholder="Téléphone" value={this.state.utilisateur.telephone} onChange={this.handleInput} required />
                                            </div>
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="condition-utilisation" name="conditionUtilisation" checked={this.state.utilisateur.conditionUtilisation} onChange={this.handleInput} />
                                                    <label className="custom-control-label" htmlFor="condition-utilisation">Je confirme que les informations renseignées sont valides et sont les miens.</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Nouveau mot de passe</label>
                                                <input className="form-control" type="password" name="pass" placeholder="Votre nouveau mot de passe" value={this.state.utilisateur.pass} onChange={this.handleInput} disabled={!this.state.utilisateur.conditionUtilisation} required />
                                            </div>
                                            <div className="form-group">
                                                <label>Repéter le mot de passe</label>
                                                <input className="form-control" type="password" name="pass2" placeholder="Re-saisie le mot de passe" value={this.state.utilisateur.pass2} onChange={this.handleInput} disabled={!this.state.utilisateur.conditionUtilisation} required />
                                            </div>
                                        </div>
                                        <div className="card-footer text-right">
                                            <button type="submit" className="btn btn-primary" disabled={!this.state.utilisateur.conditionUtilisation || this.state.utilisateur.pass!=this.state.utilisateur.pass2 || this.state.utilisateur.pass==""}>Demander mon mot de passe</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 mb-3 text-center">
                            <Link to="/">J'ai retrouvé mon mot de passe. Me connecter.</Link>
                        </div>
                    </div>
                </div>
            </IonContent>
        );
    }
}