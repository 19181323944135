import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import './bootstrap.css';

import {modesPaiementMobile, montantMax, montantMin} from '../..';
import {Data} from '../services/data-manager';
import { notifyDanger, notifySuccess } from '../utils';
import Auth from '../services/Auth';
import { IonContent } from '@ionic/react';
import { Capacitor } from '@capacitor/core';

interface IProps {
    auth: Auth,
    isMomo: boolean,
    isCashIn?: boolean,
    isCashOut?: boolean,
    isLoading: Function
};
interface IState {
    transaction: {
        credential: string | null,
        idUtilisateur: number | null,
        pays: string,
        beneficiaire: string,
        montant: number,
        operateur: string,
        numero: string,
        devise: string | null,
        isYup: boolean,
        codeYup: string,
        isMomo: boolean,
        isCashIn?: boolean,
        isCashOut?: boolean
    },
    showConfirmation: boolean,
    showYupCode: boolean,
    showAttente: boolean,
    currency: any
};

export default class Transaction extends React.Component<IProps,IState> {

    nTimeoutStatus:number;

    constructor(props: IProps | Readonly<IProps>) {
        super(props);
        this.state = {
            transaction: {
                credential: null,
                idUtilisateur: null,
                pays: "",
                beneficiaire: "",
                montant: 0,
                operateur: "",
                numero: "",
                devise: null,
                isYup: false,
                codeYup: "",
                isMomo: this.props.isMomo,
                isCashIn: this.props.isCashIn,
                isCashOut: this.props.isCashOut
            },
            showConfirmation: false,
            showYupCode: false,
            showAttente: false,
            currency: {
                source: "",
                destination: "",
                montant: 0,
                taux: 0
            }
        };
        this.nTimeoutStatus = 0;
        this.sendTransaction = this.sendTransaction.bind(this);
        this.handleInputTransaction = this.handleInputTransaction.bind(this);
        this.confirmModalShow = this.confirmModalShow.bind(this);
        this.attenteModalShow = this.attenteModalShow.bind(this);
        this.yupModalShow = this.yupModalShow.bind(this);
        this.confirmationYUP = this.confirmationYUP.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.getTaux = this.getTaux.bind(this);
    }

    componentDidMount() {
        this.props.auth.refreshCredential();
    }

    componentWillUnmount() {
        if (this.nTimeoutStatus) clearTimeout(this.nTimeoutStatus);
    }

    resetForm() {
        let transaction = this.state.transaction;
        transaction.numero = "";
        transaction.montant = 0;
        transaction.beneficiaire = "";
        this.setState({transaction: transaction});
    }

    sendTransaction(event:any, avecConfirmation=false) {
        event.preventDefault();

        if (!this.props.auth.isAuthenticated()) return false;
        let transaction = this.state.transaction;
        this.props.auth.refreshCredential();
        transaction.idUtilisateur = this.props.auth.utilisateur.id;
        transaction.credential = this.props.auth.getCredential();
        // Vérifications
        if (transaction.isYup && transaction.codeYup==="") { // Code YUP
            new Data().exec("/get-yup-code", transaction);
            this.yupModalShow(true);
            return false;
        }
        if (!avecConfirmation) { // Vérification
            if (!transaction.isMomo || !transaction.isCashOut) {
                this.confirmModalShow(true);
                return false;
            }
        } else {
            this.confirmModalShow(false);
        }

        this.props.isLoading(true);
        new Data()
        .exec("/transaction", transaction)
        .then(res => {
            //console.log("Transaction effectuée : ", res);
            if (res.code==200 || res.code==520) {
                this.props.auth.update(res.utilisateur, res.nouveauCredential, true);
                this.getStatus(res.transaction);
                this.resetForm();
            } else {
                notifyDanger(res.message);
            }
        })
        .catch((error) => {
            window.alert("Une erreur c'est produite. Vérifier votre connexion et reessayer.");
            console.warn("Erreur : ", error);
        })
        .finally(() => {
            this.props.isLoading(false);
        });
    }

    getStatus(transaction:any) {
        if (this.nTimeoutStatus==0) {
            if (!this.state.showAttente) this.attenteModalShow(true);
        }
        new Data()
        .exec("/transaction/status?idTransaction="+transaction.id+"&isYup="+((transaction.operateur=="YUP" || transaction.operateur=="YUP-CI")?1:0))
        .then(res => {
            //console.log("Etat : ", res);
            if (res.code==200) {
                if (res.transaction.status=="PENDING") {
                    this.nTimeoutStatus = window.setTimeout(this.getStatus, 2500, transaction);
                } else {
                    if (res.transaction.status=="SUCCESSFUL") {
                        notifySuccess("Transaction OK !");
                    } else {
                        notifySuccess("Echec ! La transaction à échouée ou n'a pas été confirmée.");
                    }
                    clearTimeout(this.nTimeoutStatus);
                    this.nTimeoutStatus = 0;
                    this.attenteModalShow(false);
                }
                this.props.auth.update(res.utilisateur, res.nouveauCredential, true);
                this.resetForm();
            } else {
                notifyDanger(res.message);
            }
        });
    }

    handleInputTransaction(event:any) {
        let target:any = event.target;
        const name = target.name;
        const value = target.value;
        let transaction:any = this.state.transaction;
        transaction[name] = value;
        if (name==="operateur") {
            const modeChoisi = modesPaiementMobile.filter(m => (m.operateur===value));
            if (modeChoisi.length===1) transaction.devise = modeChoisi[0].devise;
            else transaction.devise = "";
            transaction.isYup = (value==="YUP" || value==="YUP-CI");
            transaction.codeYup = "";
            if (transaction.devise!=this.props.auth.utilisateur.devise) {
                this.getTaux();
            }
        }
        this.setState({transaction: transaction});
    }

    getTaux() {
        new Data().exec("/currency", {
            montant: 1,
            source: this.state.transaction.devise,
            destination: this.props.auth.utilisateur.devise
        })
        .then(res => {
            //console.log(res);
            this.setState({currency: res});
        });
    }

    confirmModalShow(show=true) {
        this.setState({showConfirmation: show});
    }

    attenteModalShow(show:boolean) {
      this.setState({showAttente: show});
    }

    yupModalShow(show:boolean) {
      this.setState({showYupCode: show});
    }
  
    confirmationYUP(e:any) {
        if (this.state.transaction.codeYup=="") {
            notifyDanger("Veuillez entrer le code de confirmation YUP.");
            return false;
        }
        this.yupModalShow(false);
        this.sendTransaction(e, this.props.isCashIn);
    }

    render() {
        return (
            <IonContent className="alert-secondary">
                <div className="container-fluid mt-5 pt-2 pb-3">
                    <form action="/transaction" method="post" className="contactForm" onSubmit={(e) => {this.sendTransaction(e, this.props.isCashIn)}}>
                        <div className="">
                            <div className="row">
                                <div className={"col-sm-12 mb-3 " + (Capacitor.platform==="ios" ? "mt-5":"mt-3")}>
                                    {(this.props.isMomo && this.props.isCashIn) && <h3>Envoyer de l'argent</h3>}
                                    {(this.props.isMomo && this.props.isCashOut) && <h3>Recevoir de l'argent</h3>}
                                    {(!this.props.isMomo) && <h3>Recharge de crédit</h3>}
                                    <hr className="border-secondary" />
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="pays">Pays</label>
                                        <select name="pays" id="pays" value={this.state.transaction.pays} className="form-control border-secondary" onChange={(e) => this.handleInputTransaction(e)} required>
                                            <option value=""></option>
                                            {( modesPaiementMobile.sort((a,b) => {
                                                if (a.pays<b.pays) return -1;
                                                if (a.pays>b.pays) return 1;
                                                return 0;
                                            })
                                            .filter((item,pos) => (pos==0 || (item.pays!=modesPaiementMobile[pos-1].pays)))
                                            .map(m => <option value={ m.pays } key={'mode-source-'+m.pays}>{ m.paysLabel }</option> ) )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="operateur">Opérateur</label>
                                        <select name="operateur" id="operateur" value={this.state.transaction.operateur} className="form-control border-secondary" onChange={(e) => this.handleInputTransaction(e)} required>
                                            <option value=""></option>
                                            {( modesPaiementMobile.map(m => (this.props.isMomo && m.hasMomo && (m.pays==this.state.transaction.pays))?<option value={ m.operateur } key={'mode-source-'+m.operateur}>{ m.name }</option>:'') )}
                                            {( modesPaiementMobile.map(m => (!this.props.isMomo && m.hasAirtime && (m.pays==this.state.transaction.pays))?<option value={ m.operateur } key={'mode-source-'+m.operateur}>{ m.name }</option>:'') )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="numero">Numéro du bénéficiatiaire</label>
                                        <input type="number" name="numero" id="numero" value={this.state.transaction.numero} className="form-control border-secondary" placeholder="Numéro de téléphone" onChange={(e) => this.handleInputTransaction(e)} required />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="montant">Montant</label>
                                        <input type="number" name="montant" value={this.state.transaction.montant} id="montant" className="form-control border-secondary" onChange={(e) => this.handleInputTransaction(e)} required />
                                    </div>
                                </div>
                                {((this.state.transaction.montant<=0) && (this.state.transaction.montant<montantMin(this.state.transaction.pays, !this.props.isMomo) || this.state.transaction.montant>montantMax(this.state.transaction.pays, !this.props.isMomo))) &&
                                <div className="col-sm-12">
                                    <div className="alert alert-danger">
                                        Le montant doit être compris entre <strong>{ montantMin(this.state.transaction.pays, !this.props.isMomo) }</strong> et <strong>{ montantMax(this.state.transaction.pays, !this.props.isMomo) }</strong> {this.state.transaction.devise}.
                                    </div>
                                </div>
                                }
                                {(this.state.transaction.devise!=this.props.auth.utilisateur.devise && this.state.currency.destination!="") &&
                                <div className="col-sm-12">
                                    <div className="alert alert-warning text-small">
                                        {(this.props.isMomo && this.props.isCashOut)?
                                        <div>
                                            Frais de transaction : {this.state.currency.frais*100}% = { this.state.transaction.montant*this.state.currency.frais } <sup>{this.state.currency.source}</sup> <br/>
                                            Montant à créditer : <strong>{ Intl.NumberFormat().format(this.state.transaction.montant*(1-this.state.currency.frais)) } <sup>{this.state.currency.source}</sup></strong> <br/>
                                            Taux d'échange : 1 <sup>{this.state.currency.source}</sup> = {this.state.currency.montant} <sup>{this.state.currency.destination}</sup> <br/>
                                            Vous serez crédité de : <strong>{ Intl.NumberFormat().format(this.state.transaction.montant*this.state.currency.montant*(1-this.state.currency.frais)) } <sup>{this.state.currency.destination}</sup></strong>
                                        </div>
                                        :
                                        <div>
                                            Frais de transaction : {this.state.currency.frais*100}% = { this.state.transaction.montant*this.state.currency.frais } <sup>{this.state.currency.source}</sup> <br/>
                                            Montant à débiter : <strong>{ Intl.NumberFormat().format(this.state.transaction.montant*(1+this.state.currency.frais)) } <sup>{this.state.currency.source}</sup></strong> <br/>
                                            Taux d'échange : 1<sup>{this.state.currency.source}</sup> = {this.state.currency.montant} <sup>{this.state.currency.destination}</sup> <br/>
                                            Vous serez débité de : <strong>{ Intl.NumberFormat().format(this.state.transaction.montant*this.state.currency.montant*(1+this.state.currency.frais)) } <sup>{this.state.currency.destination}</sup></strong>
                                        </div>
                                        }
                                    </div>
                                </div>
                                }
                                <div className="col-sm-12 mt-3 pb-5 mb-5">
                                    <button className="btn btn-primary btn-block btn-lg mb-5" type="submit">Demarrer</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <Modal className="mt-5" show={this.state.showConfirmation} onHide={() => {this.confirmModalShow(false)}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation du bénéficiaire</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Êtes vous sûr de vouloir transférer
                            &nbsp;
                            <strong>
                                {Intl.NumberFormat().format(this.state.transaction.montant)} {this.state.transaction.devise}
                            </strong>
                            &nbsp;
                            au numéro
                            &nbsp;
                            <strong>
                                {this.state.transaction.numero}
                            </strong>
                            &nbsp;
                            au nom de
                            &nbsp;
                            <strong>
                                {this.state.transaction.beneficiaire}
                            </strong>
                            &nbsp; ?
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={() => {this.confirmModalShow(false)}} type="button">Annuler</button>
                            <button className="btn btn-primary" onClick={(e) => {this.sendTransaction(e, true);}} type="button">Confirmer</button>
                        </Modal.Footer>
                    </Modal>
                    <Modal className="mt-5" show={this.state.showYupCode} onHide={() => {this.yupModalShow(false)}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Code YUP</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group">
                                <label id="codeYup">Code YUP</label>
                                <input type="number" name="codeYup" id="codeYup" value={this.state.transaction.codeYup} className="form-control border-secondary" placeholder="Entrer le code de sécurité YUP" onChange={(e) => {this.handleInputTransaction(e)}} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {this.yupModalShow(false)}}>
                                Fermer
                            </Button>
                            <Button variant="primary" onClick={(e) => this.confirmationYUP(e)}>
                                Confirmer
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal className="mt-5" show={this.state.showAttente} onHide={() => {this.attenteModalShow(false)}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Attente de confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(this.state.transaction.isMomo && this.state.transaction.isCashOut)?
                            <h3>En attente de confirmation...</h3>:
                            <h3>Transaction en cours...</h3>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {this.attenteModalShow(false)}}>
                                Fermer
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </IonContent>
        );
    }
};