import React from 'react';
import { Button } from 'react-bootstrap';

import './bootstrap.css';

import { Data } from '../services/data-manager';
import { notifyDanger, notifySuccess } from '../utils';
import Auth from '../services/Auth';
import { IonContent } from '@ionic/react';
import { Capacitor } from '@capacitor/core';

interface IProps {
    auth: Auth,
    isLoading: Function
};
interface IState {
    approvisionnement: {
        credential: string | null,
        idUtilisateur: number | null,
        idDestinataire: number | null,
        montant: number,
        devise: string | null,
        nomDestinataire: string
        deviseDestinataire: string,
        montantDestination: number | null,
        commentaire: string,
        type: number
    },
    showAttente: boolean
};

export default class TransfertFond extends React.Component<IProps,IState> {

    constructor(props: IProps | Readonly<IProps>) {
        super(props);
        this.state = {
            approvisionnement: {
                credential: null,
                idUtilisateur: null,
                idDestinataire: null,
                montant: 0,
                devise: null,
                nomDestinataire: "",
                deviseDestinataire: "",
                montantDestination: null,
                commentaire: "",
                type: 2
            },
            showAttente: false
        };
        this.sendTransfert = this.sendTransfert.bind(this);
        this.handleInputApprovisionnement = this.handleInputApprovisionnement.bind(this);
        this.checkDestinataire = this.checkDestinataire.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    componentDidMount() {
        this.props.auth.refreshCredential();
        if (this.props.auth.isAuth) {
            let approvisionnement = this.state.approvisionnement;
            approvisionnement.idUtilisateur = this.props.auth.utilisateur.id;
            approvisionnement.devise = this.props.auth.utilisateur.devise;
            approvisionnement.credential = this.props.auth.getCredential();
            this.setState({approvisionnement: approvisionnement});
        }
    }

    resetForm() {
        let approvisionnement = this.state.approvisionnement;
        approvisionnement.montant = 0;
        approvisionnement.idDestinataire = null;
        approvisionnement.nomDestinataire = "";
        approvisionnement.deviseDestinataire = "";
        approvisionnement.montantDestination = 0;
        approvisionnement.commentaire = "";
        this.setState({approvisionnement: approvisionnement});
    }

    sendTransfert(event:any) {
        event.preventDefault();

        if (!this.props.auth.isAuthenticated()) return false;
        this.props.auth.refreshCredential()
        .then(_ => {
            let approvisionnement = this.state.approvisionnement;
            new Data()
            .exec("/approvisionnement/transfert", approvisionnement)
            .then(res => {
                console.log("Transfert effectué : ", res);
                if (res.code==200 || res.code==520) {
                    this.props.auth.update(res.utilisateur, res.nouveauCredential, true);
                    notifySuccess("Le transfert de fond à été effectué avec succès !");
                    this.resetForm();
                } else {
                    notifyDanger(res.message);
                }
            });
        })
        .catch(error => {
            console.warn("Error : ", error);
            notifyDanger("Une erreur est survenu pendant l'opération. Veullez réessayer !");
        })
    }

    handleInputApprovisionnement(event:any) {
        let target:any = event.target;
        const name = target.name;
        const value = target.value;
        let approvisionnement:any = this.state.approvisionnement;
        approvisionnement[name] = value;
        if (name=="idDestinataire") {
            approvisionnement.nomDestinataire = "";
            approvisionnement.deviseDestinataire = "";
            approvisionnement.montantDestination = 0;
        }
        this.setState({approvisionnement: approvisionnement});
    }

    checkDestinataire() {
        let approvisionnement = this.state.approvisionnement;
        new Data()
        .getOne("Utilisateur", `id=${approvisionnement.idDestinataire}`)
        .then(res => {
            if (!res.nom) throw Error("Utilisateur inconnue.");
            approvisionnement.nomDestinataire = res.nom + " " + res.prenom;
            approvisionnement.deviseDestinataire = res.devise;
            new Data().exec("/currency", {
                montant: approvisionnement.montant,
                source: approvisionnement.devise,
                destination: res.devise
            })
            .then(currency => {
                let approvisionnement = this.state.approvisionnement;
                approvisionnement.montantDestination = Math.floor(currency.montant*(1-(this.props.auth.utilisateur.pays==res.pays ? 0 : currency.frais)));
                approvisionnement.commentaire = `Transfert de ${approvisionnement.montant} ${approvisionnement.devise} de ${this.props.auth.utilisateur.nom} ${this.props.auth.utilisateur.prenom} à ${approvisionnement.nomDestinataire}${(this.props.auth.utilisateur.pays==res.pays)?"":" aux frais de " + Math.round(currency.frais*100) + "%. Net à percevoir " + approvisionnement.montantDestination + " " + approvisionnement.deviseDestinataire}.`;
                this.setState({approvisionnement: approvisionnement});
            })
            .catch(error => {
                console.warn("Erreur : ", error);
                this.setState({approvisionnement: approvisionnement});
            });
        })
        .catch(error => {
            console.warn("Error : ", error);
            notifyDanger("L'utilisateur est introuvable. Vérifier si le numéro es correct.");
            approvisionnement.nomDestinataire = "";
            approvisionnement.deviseDestinataire = "";
            approvisionnement.montantDestination = 0;
            this.setState({approvisionnement: approvisionnement});
        });
    }

    render() {
        return (
            <IonContent className="alert-secondary">
                <div className="container-fluid mt-5 pt-2 pb-3">
                    <form action="/transfert" method="post" className="contactForm" onSubmit={(e) => {this.sendTransfert(e)}}>
                        <div className="">
                            <div className="row">
                                <div className={"col-sm-12 mb-3 " + (Capacitor.platform==="ios" ? "mt-5":"mt-3")}>
                                    <h3>Transfert de fond</h3>
                                    <hr className="border-secondary" />
                                </div>
                                <div className="col-8">
                                    <div className="form-group">
                                        <label htmlFor="montant">Montant à transférer</label>
                                        <input type="number" name="montant" value={this.state.approvisionnement.montant} id="montant" className="form-control border-secondary" onChange={(e) => this.handleInputApprovisionnement(e)} required />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label htmlFor="devise">&nbsp;</label>
                                        <input name="text" id="devise" value={this.state.approvisionnement.devise || ""} className="form-control border-secondary" disabled required />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="idDestinataire">Code du bénéficiaire</label>
                                        <input type="number" name="idDestinataire" id="idDestinataire" value={this.state.approvisionnement.idDestinataire || ""} className="form-control border-secondary" placeholder="Code du bénéficiaire" onChange={(e) => this.handleInputApprovisionnement(e)} required />
                                    </div>
                                </div>
                                <div className="col-sm-12 mb-3">
                                    <button className="btn btn-outline-secondary btn-block" type="button" onClick={(e) => this.checkDestinataire()}>Vérifier le bénéficiaire</button>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="nomDestinataire">Nom du bénéficiaire</label>
                                        <input type="text" name="nomDestinataire" id="nomDestinataire" value={this.state.approvisionnement.nomDestinataire} className="form-control border-secondary" disabled required />
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="form-group">
                                        <label htmlFor="montantDestination">Montant à percevoir</label>
                                        <input type="number" name="montantDestination" value={this.state.approvisionnement.montantDestination || ""} className="form-control border-secondary" disabled required />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label htmlFor="deviseDestinataire">&nbsp;</label>
                                        <input name="text" id="deviseDestinataire" value={this.state.approvisionnement.deviseDestinataire} className="form-control border-secondary" disabled required />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="alert alert-danger text-small">
                                        <div>
                                            <b>Attention :</b>
                                        </div>
                                        <div>
                                            Une fois le transfert initié, il n'y a plus de retour possible. 
                                            Rassurez-vous que le nom du bénéficiaire est celui à qui vous envoyez les fonds.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 mt-3 pb-5 mb-5">
                                    <button className="btn btn-primary btn-block btn-lg mb-5" type="submit" disabled={this.state.approvisionnement.montant<=0 || this.state.approvisionnement.nomDestinataire==""}>Transférer</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </IonContent>
        );
    }
};