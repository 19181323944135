import React from 'react';

import TableDisplay from './TableDisplay';

import { Data } from '../services/data-manager';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';
import { IonContent } from '@ionic/react';
import { Capacitor } from '@capacitor/core';

export default class Approvisionnements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            approvisionnements: [],
            page: 1,
            nbApprovisionnement: 1,
            sizeApprovisionnement: 50,
            approvisionnement: null,
            showDetail: false
        };
        this.getApprovisionnements = this.getApprovisionnements.bind(this);
        this.getDetailApprovisionnement = this.getDetailApprovisionnement.bind(this);
        this.showDetailModal = this.showDetailModal.bind(this);
    }
    componentDidMount() {
        this.getApprovisionnements();
    }
    getApprovisionnements(page=0) {
        if (!this.props.auth.isAuthenticated()) return false;
        if (page!==0) {
            this.setState({page: page});
        } else {
            page = this.state.page;
        }
        this.props.isLoading(true);
        new Data()
        .get("Approvisionnement", `idClient=${this.props.auth.utilisateur.id}`, "", "dateCreation DESC", page, this.state.sizeApprovisionnement)
        .then(res => {
            res.rows.forEach(appro => {
                appro.type = appro.type==0 ? "APPROVISIONNEMENT" : (appro.type==1 ? "RETRAIT" : "TRANSFERT");
                if (appro.type==1 || (appro.type==2 && this.props.auth.utilisateur.id==appro.idUtilisateur)) {
                    appro.montant = -appro.montant;
                }
            });
            this.setState({approvisionnements: res.rows, nbApprovisionnement: res.count});
        })
        .catch(err => {
            console.warn("Error : ", err);
        })
        .finally(() => {
            this.props.isLoading(false);
        });
        setTimeout(() => {window.scrollTo(0,0);}, 500);
    }
    getDetailApprovisionnement(approvisionnement) {
        this.setState({approvisionnement: approvisionnement}, () => {
            this.showDetailModal();
        });
    }
    showDetailModal(show=true) {
        this.setState({showDetail: show});
    }
    render() {
        return (
            <IonContent className="alert-light">
                <div className="container-fluid mt-5 pt-2">
                    <div className="row">
                        <div className={"col-sm-12 mb-3 " + (Capacitor.platform==="ios" ? "mt-5":"mt-3")}>
                            <h3>Approvisionnements [Page {this.state.page}/{(this.state.nbApprovisionnement===0)?1:Math.ceil(this.state.nbApprovisionnement/this.state.sizeApprovisionnement)}]</h3>
                        </div>
                        <div className="col-sm-12 mb-3">
                            <TableDisplay 
                                tableStriped={true}
                                tableHover={true}
                                tableClass="alert-light"
                                thead="dark"
                                titles={["TYPE", "MONTANT", "", "DATE"]}
                                indices={["type", "montant", "devise", "dateCreation"]}
                                data={this.state.approvisionnements}
                                withPaging={true}
                                pagingTheme="primary"
                                nb={this.state.nbApprovisionnement}
                                size={this.state.sizeApprovisionnement}
                                current={this.state.page}
                                onChangePage={this.getApprovisionnements} />
                        </div>
                    </div>
                    {(this.state.approvisionnement) &&
                    <Modal show={this.state.showDetail} onHide={() => {this.showDetailModal(false)}} className="mt-5">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Détails de la approvisionnement #{this.state.approvisionnement.id}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mx-3">
                                <table className="table table-sm">
                                    <tr className="row">
                                        <td className="col-sm-5">ID : </td>
                                        <td className="col-sm-7">{this.state.approvisionnement.id}</td>
                                    </tr>
                                    <tr className="row">
                                        <td className="col-sm-5">Montant : </td>
                                        <td className="col-sm-7">{Intl.NumberFormat().format(this.state.approvisionnement.montant)} {this.state.approvisionnement.devise}</td>
                                    </tr>
                                    <tr className="row">
                                        <td className="col-sm-5">Date : </td>
                                        <td className="col-sm-7"><Moment format="DD/MM/YYYY à HH:mm">{this.state.approvisionnement.dateCreation}</Moment></td>
                                    </tr>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                    }
                </div>
            </IonContent>
        );
    }
}