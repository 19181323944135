import {apiBaseURL} from '../..';

class Data {

    send(uri, data) {
        console.log(data);
        var body = JSON.stringify(data);
        var url = apiBaseURL+uri;
        var result = fetch(url, {
            method: "POST",
            body: body
        })
        .then(res => res.json());
        return result;
    }

    get(model, where="1", relations="", order="id", page=1, pageSize=20) {
        return fetch(`${apiBaseURL}/${model}/get?where=${where}&relations=${relations}&order=${order}&page=${page}&page_size=${pageSize}`)
        .then(res => res.json());
    }

    getOne(model, where="1", relations="") {
        return fetch(`${apiBaseURL}/${model}/getone?where=${where}&relations=${relations}`)
        .then(res => res.json());
    }

    add(model, data) {
        return fetch(`${apiBaseURL}/${model}/add`, {
            method: "POST",
            body: JSON.stringify(data)
        })
        .then(res => res.json());
    }

    update(model, data, where) {
        return fetch(`${apiBaseURL}/${model}/update?where=${where}`, {
            method: "POST",
            body: JSON.stringify(data)
        })
        .then(res => res.json());
    }

    delete(model, where) {
        return fetch(`${apiBaseURL}/${model}/delete?where=${where}`)
        .then(res => res.json());
    }

    exec(uri, data=null){
        return fetch(`${apiBaseURL}${uri}`, {
            method: (data==null)?"GET":"POST",
            body: (data==null)?null:JSON.stringify(data)
        })
        .then(res => res.json());
    }

}


class Pays {
    pays = [];
    constructor() {
        new Data()
        .get("Pays")
        .then(pays => {
            this.pays = pays;
        });
    }
    get(id) {
        for (let i = 0; i < this.pays.length; i++) {
            if (this.pays[i].id===id) return this.pays[i];
        }
        return null;
    }
}

export {Data, Pays};