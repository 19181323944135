import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { checkmarkCircleSharp, removeCircleSharp, closeCircleSharp } from 'ionicons/icons';

class TableDisplay extends React.Component {
    static defaultProps = {
        titles: ["Champs 1"],
        indices: [],
        data: {},
        table: true,
        tableStriped: false,
        tableHover: false,
        tableClass: "",
        thead: "primary",
        actions: [],
        withLink: false,
        withPaging: false,
        nb: 0,
        size: 100,
        current: 1,
        pagingTheme: "primary",
        onClick: (data) => {},
        onChangePage: (page) => {}
    }
    render() {
        // Table classes
        let tableClass = "";
        if (this.props.table) tableClass += "table ";
        if (this.props.tableStriped) tableClass += "table-striped ";
        if (this.props.tableHover) tableClass += "table-hover ";
        if (this.props.tableClass) tableClass +=  " " + this.props.tableClass;
        // Pagination
        let NB = 1;
        let TAB_PAGES = [];
        if (this.props.withPaging) {
            NB = (this.props.nb===0)?1:Math.ceil(this.props.nb/this.props.size);
            for(let i=0; i<NB; i++) TAB_PAGES.push(i+1);
        }
        // Computation
        let TOTAL = {};
        // Output
        return (
            <div className="table-pane">
                <table className={tableClass} style={{fontSize: "12px"}}>
                    <thead className={"thead-"+this.props.thead}>
                        <tr>
                            {this.props.titles.map((title, index) => <th key={"table-titre-"+index}>{title}</th>)}
                            {(this.props.actions.lenght!==0) && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {
                        this.props.data.map((d, index) => (
                        <tr key={"table-ligne-"+index} onClick={() => {this.props.onClick(d)}}>
                            {this.props.indices.map((indice, i) => {
                                let value = d[indice];
                                let type = typeof value;
                                let align = "left";
                                if (!TOTAL[indice]) {
                                    TOTAL[indice] = 0; //(type==="number" || type==="bigint")?:"";
                                }
                                if (type==="number" || type==="bigint" || (type==="string" && !isNaN(value))) {
                                    TOTAL[indice] += +value;
                                    value = new Intl.NumberFormat().format(value);
                                    align="right";
                                } else if (type==="boolean") {
                                    value = <i>{(value)?"Oui":"Non"}</i>;
                                } else if (indice==="status" && (["SUCCESSFUL","PENDING","FAILED"].indexOf(value)>=0)) {
                                    value = (
                                    <IonIcon slot="start" style={{fontSize: "22px"}} className={"text-"+((value=="SUCCESSFUL")?"success":((value=="FAILED")?"danger":"warning"))} ios={(value=="SUCCESSFUL")?checkmarkCircleSharp:((value=="FAILED")?closeCircleSharp:removeCircleSharp)} md={(value=="SUCCESSFUL")?checkmarkCircleSharp:((value=="FAILED")?closeCircleSharp:removeCircleSharp)}></IonIcon>
                                    );
                                } else if (value!==null) {
                                    if (value.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/)!==null) {
                                        value = <Moment format="DD/MM/YYYY à HH:mm">{value}</Moment>;
                                    }
                                }
                                return <td key={"table-row-"+index+"-"+i} align={align}>{(i===0 && this.props.withLink)?<Link to={this.props.withLink+d[indice]}>{value}</Link>:value}</td>
                            })}
                            {(this.props.actions.lenght!==0)?<td>{this.props.actions.map((action, bindex) => <button key={"option-button-"+index+"-"+bindex} type="button" className={action.className} onClick={()=>{action.onClick(d)}}>{action.caption}</button>)}</td>:''}
                        </tr>
                        ))}
                    </tbody>
                    {(this.props.withFooter) &&
                    <tfoot className={"thead-"+this.props.thead}>
                        <tr>
                            {Object.keys(TOTAL).map((indice, i) => <th key={"footer-row-"+i}>{(i===0)?"":new Intl.NumberFormat().format(TOTAL[indice])}</th>)}
                            {(this.props.actions.lenght!==0) && <th></th>}
                        </tr>
                    </tfoot>
                    }
                </table>
                {(NB>1) &&
                <div className="text-center">
                    {TAB_PAGES.map((i, index) => <div key={"pagination-btn-"+index} className={"btn btn-"+((i===this.props.current)?"outline-":"")+this.props.pagingTheme+" mr-1"} onClick={() => {if (i!==this.props.current) {this.props.onChangePage(i)}}}>{i}</div>
                    )}
                </div>
                }
            </div>
        );
    }
}

export default TableDisplay;