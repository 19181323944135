import React from 'react';
import { Capacitor, Plugins } from '@capacitor/core';
import { IonApp, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonRouterOutlet, IonSplitPane, IonTitle, IonToast, IonToolbar, IonInfiniteScroll } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';

import imgLogo from './images/logo1024x1024.png';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Bootstrap CSS */
import './components/bootstrap.css';


/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Ajustement pour tablettes */
import "./theme/ajustement.css";

import "./App.css";

import Menu from './components/Menu';
import Solde from './components/Solde';
import Auth from './services/Auth';
import Transactions from './components/Transactions';
import Approvisionnements from './components/Approvisionnements';
import Login from './components/Login';
import SignIn from './components/SignIn';
import Accueil from './components/Accueil';
import Depot from './components/Depot';
import Retrait from './components/Retrait';
import Recharge from './components/Recharge';
import Facture from './components/Facture';
import Apropos from './components/Apropos';
import TransfertFond from './components/TransfertFond';

/* Import personal CSS */
import "./App.css";
import { Modal } from 'react-bootstrap';
import { Data } from './services/data-manager';
import { RETRAIT_REFRESH_TIMER } from './config';
import RecupPass from './components/RecupPass';
import RechargeCarte from './components/RechargeCarte';


interface AppStateInterface {
  auth: Auth, 
  isLoading: boolean,
  nouvellesTransactionsRetraits: Array<any>,
  lastRetrait: any,
  showModalNouvelleTransactionRetrait: boolean,
  isRedirect: boolean,
  toRedirect: string
}

export default class App extends React.Component<{},AppStateInterface> {

  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      auth: new Auth(),
      isLoading: false,
      nouvellesTransactionsRetraits: [],
      lastRetrait: null,
      showModalNouvelleTransactionRetrait: false,
      isRedirect: false,
      toRedirect: ""
    }
    this.isLoading = this.isLoading.bind(this);
    this.getNouvelleTransactionRetrait = this.getNouvelleTransactionRetrait.bind(this);
    this.showModalNouvelleTransactionRetrait = this.showModalNouvelleTransactionRetrait.bind(this);
    this.openTransactions = this.openTransactions.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
  }

  componentDidMount() {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        if (window.location.pathname === "/") {
          this.closeApp();
        }
      });
    }
    if (window.location.pathname != "/transactions") {
      this.getNouvelleTransactionRetrait();
    }
  }
  
  closeApp() {
    if (window.confirm("Voulez-vous vraiment quitter l'application ?")===true) Plugins.App.exitApp();
  }

  isLoading(state: boolean) {
    this.setState({isLoading: state});
  }

  getNouvelleTransactionRetrait() {
    if (this.state.auth.utilisateur) {
      new Data()
      .exec("/utilisateur/transaction/get-new-retrait", {
        credential: this.state.auth.getCredential(),
        idUtilisateur: this.state.auth.utilisateur.id
      })
      .then(res => {
        if (res.code==200) {
          if (res.retraits.length>0) {
            if (!this.state.lastRetrait) {
              this.setState({lastRetrait: res.retraits[0]});
              this.showModalNouvelleTransactionRetrait();
            }
            else if (this.state.lastRetrait.id!=res.retraits[0].id) {
              this.setState({lastRetrait: res.retraits[0]});
              this.showModalNouvelleTransactionRetrait();
            }
            this.setState({nouvellesTransactionsRetraits: res.retraits});
          } 
        }
      })
      .catch((error) => {
        console.warn("Erreur : ", error);
      })
      .finally(() => {
        setTimeout(() => {
          this.getNouvelleTransactionRetrait();
        }, RETRAIT_REFRESH_TIMER);
      })
    }
  }

  showModalNouvelleTransactionRetrait(show:boolean=true) {
    this.setState({showModalNouvelleTransactionRetrait: show});
  }

  openTransactions() {
    this.showModalNouvelleTransactionRetrait(false);
    this.redirectTo("/transactions");
  }

  redirectTo(uri:string) {
    window.location.href = uri;
  }

  render() {
    return (
      <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu auth={this.state.auth} />
            <IonPage>
              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="start">
                    <IonMenuButton />
                  </IonButtons>
                  <IonTitle>AFRIC PAY</IonTitle>
                </IonToolbar>
              </IonHeader>

              <IonContent fullscreen scrollEvents={true}>
                <IonHeader collapse="condense">
                  <IonToolbar>
                    <IonTitle size="large">AFRIC PAY</IonTitle>
                  </IonToolbar>
                </IonHeader>
                <IonRouterOutlet id="main" style={{overflow: "auto"}}>
                  <Route path="/" exact >
                    {(this.state.auth.isAuth)?<Accueil auth={this.state.auth} isLoading={this.isLoading} />:<Login auth={this.state.auth} isLoading={this.isLoading} />}
                  </Route>
                  <Route path="/transaction/depot" exact >
                    {(this.state.auth.isAuth)?<Depot auth={this.state.auth} isLoading={this.isLoading} />:<Login auth={this.state.auth} isLoading={this.isLoading} />}
                  </Route>
                  <Route path="/transaction/retrait" exact >
                    {(this.state.auth.isAuth)?<Retrait auth={this.state.auth} isLoading={this.isLoading} />:<Login auth={this.state.auth} isLoading={this.isLoading} />}
                  </Route>
                  <Route path="/transaction/recharge" exact >
                    {(this.state.auth.isAuth)?<Recharge auth={this.state.auth} isLoading={this.isLoading} />:<Login auth={this.state.auth} isLoading={this.isLoading} />}
                  </Route>
                  <Route path="/transaction/facture" exact >
                    {(this.state.auth.isAuth)?<Facture auth={this.state.auth} isLoading={this.isLoading} />:<Login auth={this.state.auth} isLoading={this.isLoading} />}
                  </Route>
                  <Route path="/transactions" exact >
                    {(this.state.auth.isAuth)?<Transactions auth={this.state.auth} isLoading={this.isLoading} />:<Login auth={this.state.auth} isLoading={this.isLoading} />}
                  </Route>
                  <Route path="/transaction/approvisionnement" exact >
                    {(this.state.auth.isAuth)?<RechargeCarte auth={this.state.auth} isLoading={this.isLoading} />:<Login auth={this.state.auth} isLoading={this.isLoading} />}
                  </Route>
                  <Route path="/approvisionnements" exact >
                    {(this.state.auth.isAuth)?<Approvisionnements auth={this.state.auth} isLoading={this.isLoading} />:<Login auth={this.state.auth} isLoading={this.isLoading} />}
                  </Route>
                  <Route path="/approvisionnements/transfert" exact >
                    {(this.state.auth.isAuth)?<TransfertFond auth={this.state.auth} isLoading={this.isLoading} />:<Login auth={this.state.auth} isLoading={this.isLoading} />}
                  </Route>
                  <Route path="/solde" exact >
                    {(this.state.auth.isAuth)?<Solde auth={this.state.auth} isLoading={this.isLoading} />:<Login auth={this.state.auth} isLoading={this.isLoading} />}
                  </Route>
                  <Route path="/signin" exact >
                    <SignIn auth={this.state.auth} isLoading={this.isLoading} />
                  </Route>
                  <Route path="/recup-pass" exact >
                    <RecupPass auth={this.state.auth} isLoading={this.isLoading} />
                  </Route>
                  <Route path="/apropos" exact >
                    <Apropos />
                  </Route>
                </IonRouterOutlet>
                {(this.state.isLoading) &&
                <div className="loading-backdrop">
                  <div className="loading-box">
                    <img className="loading-image" src={imgLogo} alt="Logo AfricPay - Transfert d'argent" />
                  </div>
                </div>
                }
                {(this.state.lastRetrait) &&
                <Modal show={this.state.showModalNouvelleTransactionRetrait} onHide={() => {this.showModalNouvelleTransactionRetrait(false)}} className="mt-5">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Nouveau retrait en attente
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Un retrait de {this.state.lastRetrait.montant} {this.state.lastRetrait.devise} à été demandé par {this.state.lastRetrait.beneficiaire}
                    </Modal.Body>
                    <Modal.Footer className="text-right">
                        <button className="btn btn-success" onClick={() => {this.openTransactions()}}>Mes transactions</button>
                        <button className="btn btn-secondary" onClick={() => {this.showModalNouvelleTransactionRetrait(false)}}>Quitter</button>
                    </Modal.Footer>
                </Modal>
                }
              </IonContent>
            </IonPage>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    );
  }
};