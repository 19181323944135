import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import './bootstrap.css';

import {modesPaiementMobile, montantMax, montantMin} from '../config';
import {Data} from '../services/data-manager';
import { notifyDanger, notifySuccess } from '../utils';
import Auth from '../services/Auth';
import { IonContent } from '@ionic/react';
import { Capacitor } from '@capacitor/core';

interface IProps {
    auth: Auth,
    isLoading: Function
};
interface IState {
    transaction: {
        credential: string | null,
        idUtilisateur: number | null,
        pays: string,
        beneficiaire: string,
        montant: number,
        operateur: string,
        numero: string,
        telephone: string,
        devise: string | null,
        isFacture: boolean,
        etatFacture: string,
        date: string,
        infos?: any[]
    },
    showConfirmation: boolean,
    currency: any
};

export default class Facture extends React.Component<IProps,IState> {

    constructor(props: IProps | Readonly<IProps>) {
        super(props);
        this.state = {
            transaction: {
                credential: null,
                idUtilisateur: null,
                pays: "",
                beneficiaire: "",
                montant: 0,
                operateur: "",
                numero: "",
                telephone: "",
                devise: null,
                isFacture: true,
                etatFacture: "",
                date: "",
                infos: []
            },
            showConfirmation: false,
            currency: {
                source: "",
                destination: "",
                montant: 0,
                taux: 0
            }
        }
        this.sendTransaction = this.sendTransaction.bind(this);
        this.handleInputTransaction = this.handleInputTransaction.bind(this);
        this.handleSearchFacture = this.handleSearchFacture.bind(this);
        this.confirmModalShow = this.confirmModalShow.bind(this);
        this.getTaux = this.getTaux.bind(this);
    }

    componentDidMount() {
        this.props.auth.refreshCredential();
    }

    resetForm() {
        let transaction = this.state.transaction;
        transaction.numero = "";
        transaction.montant = 0;
        transaction.beneficiaire = "";
        transaction.telephone = "";
        transaction.etatFacture = "";
        transaction.date = "";
        this.setState({transaction: transaction});
    }

    sendTransaction(event:any, avecConfirmation=false) {
        event.preventDefault();

        if (!this.props.auth.isAuthenticated()) return false;
        let transaction = this.state.transaction;
        transaction.idUtilisateur = this.props.auth.utilisateur.id;
        transaction.credential = this.props.auth.getCredential();
        console.log(transaction);
        if (!avecConfirmation) { // Vérification
            this.confirmModalShow(true);
            return false;
        } else {
            this.confirmModalShow(false);
        }
        
        this.props.isLoading(true);
        new Data()
        .exec("/transaction", transaction)
        .then(res => {
            console.log("Transaction effectuée : ", res);
            if (res.code==200 || res.code==520) {
                this.props.auth.update(res.utilisateur, res.nouveauCredential, true);
                notifySuccess(res.message);
                this.resetForm();
            } else {
                notifyDanger(res.message);
            }
        })
        .finally(() => {
            this.props.isLoading(false);
        });
    }

    handleInputTransaction(event:any) {
        let target:any = event.target;
        const name = target.name;
        const value = target.value;
        let transaction:any = this.state.transaction;
        transaction[name] = value;
        if (name==="operateur") {
            const modeChoisi = modesPaiementMobile.filter(m => (m.operateur===value));
            if (modeChoisi.length===1) transaction.devise = modeChoisi[0].devise;
            else transaction.devise = "";
            this.handleSearchFacture();
            if (transaction.devise!=this.props.auth.utilisateur.devise) {
                this.getTaux();
            }
        }
        this.setState({transaction: transaction});
    }

    handleSearchFacture() {
        if (this.state.transaction.numero.length>4) {
            new Data()
            .exec("/facture/details?operateur="+this.state.transaction.operateur+"&numero="+this.state.transaction.numero)
            .then(res => {
                console.log(res);
                if (res.code==200 && res.facture) {
                    if (res.facture.status!="FAILED") {
                        let transaction = this.state.transaction;
                        transaction.montant = res.facture.amount + res.facture.fees;
                        transaction.beneficiaire = res.facture.customer_name;
                        transaction.telephone = res.facture.customer_id;
                        transaction.etatFacture = res.facture.payment_status;
                        transaction.date = res.facture.bill_duedate;
                        this.setState({transaction: transaction});
                    }
                }
            });
        }
    }

    getTaux() {
        new Data().exec("/currency", {
            montant: 1,
            source: this.state.transaction.devise,
            destination: this.props.auth.utilisateur.devise
        })
        .then(res => {
            console.log(res);
            this.setState({currency: res});
        });
    }
    
    confirmModalShow(show=true) {
        this.setState({showConfirmation: show});
    }

    render() {
        return (
            <IonContent className="alert-secondary">
                <div className="container-fluid mt-5 pt-2 pb-3">
                    <form action="/transaction" method="post" className="contactForm" onSubmit={(e) => {this.sendTransaction(e, true)}}>
                        <div className="">
                            <div className="row">
                                <div className={"col-sm-12 mb-3 " + (Capacitor.platform==="ios" ? "mt-5":"mt-3")}>
                                    <h3>Payer une Facture</h3>
                                    <hr className="border-secondary" />
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="pays">Pays</label>
                                        <select name="pays" id="pays" value={this.state.transaction.pays} className="form-control border-secondary" onChange={(e) => this.handleInputTransaction(e)} required>
                                            <option value=""></option>
                                            {( modesPaiementMobile.sort((a,b) => {
                                                if (a.pays<b.pays) return -1;
                                                if (a.pays>b.pays) return 1;
                                                return 0;
                                            })
                                            .filter((item,pos) => (pos==0 || (item.pays!=modesPaiementMobile[pos-1].pays)))
                                            .map(m => <option value={ m.pays } key={'mode-source-'+m.pays}>{ m.paysLabel }</option> ) )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="operateur">Type de facture</label>
                                        <select name="operateur" id="operateur" value={this.state.transaction.operateur} className="form-control border-secondary" onChange={(e) => this.handleInputTransaction(e)} required>
                                            <option value=""></option>
                                            {( modesPaiementMobile.map(m => (m.hasBill && (m.pays==this.state.transaction.pays))?<option value={ m.operateur } key={'mode-source-'+m.operateur}>{ m.name }</option>:'') )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="numero">Numéro de la facture</label>
                                        <input type="number" name="numero" id="numero" value={this.state.transaction.numero} className="form-control border-secondary" placeholder="Numéro de la facture" onChange={(e) => this.handleInputTransaction(e)} onBlur={this.handleSearchFacture} required />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="montant">Montant</label>
                                        <input type="number" name="montant" value={this.state.transaction.montant} id="montant" className="form-control border-secondary" onChange={(e) => this.handleInputTransaction(e)} required readOnly />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="beneficiaire">Bénéficiaire</label>
                                        <input type="text" name="beneficiaire" value={this.state.transaction.beneficiaire} id="beneficiaire" className="form-control border-secondary" onChange={(e) => this.handleInputTransaction(e)} required readOnly />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="etatFacture">Etat de la facture</label>
                                        <input type="text" name="etatFacture" value={this.state.transaction.etatFacture} id="etatFacture" className="form-control border-secondary" onChange={(e) => this.handleInputTransaction(e)} required readOnly />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="date">Date limite</label>
                                        <input type="text" name="date" value={this.state.transaction.date} id="date" className="form-control border-secondary" onChange={(e) => this.handleInputTransaction(e)} required readOnly />
                                    </div>
                                </div>
                                {(this.state.transaction.devise!=this.props.auth.utilisateur.devise && this.state.currency.destination!="") &&
                                <div className="col-sm-12">
                                    <div className="alert alert-warning">
                                        <div>
                                            Frais de transaction : {this.state.currency.frais*100}% = { this.state.transaction.montant*this.state.currency.frais } <sup>{this.state.currency.source}</sup> <br/>
                                            Montant à débiter : <strong>{ Intl.NumberFormat().format(this.state.transaction.montant*(1+this.state.currency.frais)) } <sup>{this.state.currency.source}</sup></strong> <br/>
                                            Taux d'échange : 1<sup>{this.state.currency.source}</sup> = {this.state.currency.montant} <sup>{this.state.currency.destination}</sup> <br/>
                                            Vous serez débité de : <strong>{ Intl.NumberFormat().format(this.state.transaction.montant*this.state.currency.montant*(1+this.state.currency.frais)) } <sup>{this.state.currency.destination}</sup></strong>
                                        </div>
                                    </div>
                                </div>
                                }
                                <div className="col-sm-12 mt-3 pb-5 mb-5">
                                    <button className="btn btn-primary btn-block btn-lg mb-5" type="submit" disabled={this.state.transaction.montant<=0}>Payer la Facture</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <Modal className="mt-5" show={this.state.showConfirmation} onHide={() => {this.confirmModalShow(false)}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation du bénéficiaire</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Êtes vous sûr de vouloir payer la facture numéro 
                            &nbsp;
                            <strong>
                                {this.state.transaction.numero}
                            </strong>
                            &nbsp;
                            du montant de 
                            &nbsp;
                            <strong>
                                {Intl.NumberFormat().format(this.state.transaction.montant)} {this.state.transaction.devise}
                            </strong>
                            &nbsp;
                            au nom de
                            &nbsp;
                            <strong>
                                {this.state.transaction.beneficiaire}
                            </strong>
                            &nbsp; ?
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={() => {this.confirmModalShow(false)}} type="button">Annuler</button>
                            <button className="btn btn-primary" onClick={(e) => {this.sendTransaction(e, true)}} type="button">Confirmer</button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </IonContent>
        );
    }
};