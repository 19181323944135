import { Capacitor } from '@capacitor/core';
import { IonContent } from '@ionic/react';
import React from 'react';
import {Link} from 'react-router-dom';

import {notifyDanger} from '../utils/index';

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            remember: false
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleLoginForm = this.handleLoginForm.bind(this);
    }
    componentDidMount() {
    }
    handleInput(event) {
        const target = event.target;
        let name = event.target.name;
        var value;
        if (target.type==="checkbox") {
            value = target.checked;
        } else {
            value = target.value;
        }
        this.setState({[name]: value});
    }
    handleLoginForm(event) {
        event.preventDefault();
        this.props.isLoading(true);
        this.props.auth.login(this.state.username, this.state.password, this.state.remember)
        .then(ok => {
            if (!ok) {
                alert("Login ou mot de passe incorrecte.");
            }
        })
        .catch(error => {
            alert("Echec de connexion. Vérifiez votre connexion puis réessayer !");
        })
        .finally(() => {
            this.props.isLoading(false);
        });
    }
    render() {
        return (
            <IonContent className="alert-primary">
                <div className="container-fluid mt-5 text-dark">
                    <div className="row">
                        <div className={"col-sm-12 mb-3 text-center " + (Capacitor.platform==="ios" ? "mt-5":"mt-3")}>
                            <h3>Connexion</h3>
                            <h5>Connectez vous et faites vos transactions en toute sécurité.</h5>
                        </div>
                        <div className="col-sm-12 mb-3">
                            <div className="row">
                                <div className="col-sm-10 col-md-8 mx-auto">
                                    <form className="card" onSubmit={this.handleLoginForm}>
                                        <div className="card card-body">
                                            <div className="form-group">
                                                <label>Email ou Numéro de téléphone</label>
                                                <input className="form-control" type="text" name="username" placeholder="Login" value={this.state.username} onChange={this.handleInput} required />
                                            </div>
                                            <div className="form-group">
                                                <label>Mot de passe</label>
                                                <input className="form-control" type="password" name="password" placeholder="Mot de passe" value={this.state.password} onChange={this.handleInput} required />
                                            </div>
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="remember-me" name="remember" checked={this.state.remember} onChange={this.handleInput} />
                                                    <label className="custom-control-label" htmlFor="remember-me">Se souvenir de moi plutard.</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <Link to="/recup-pass">J'ai perdu mon mot de passe</Link>
                                            </div>
                                        </div>
                                        <div className="card-footer text-center">
                                            <button type="submit" className="btn btn-primary">Me Connecter</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 mb-3 text-center">
                            <Link to="/signin">Créer un compte</Link>
                        </div>
                    </div>
                </div>
            </IonContent>
        );
    }
}